import { ShoppingCartService } from "@/api/services/shoppingCart.service";
import { CustomerType } from "@/api/models/customer";
import { ShoppingCart } from "@/api/models/shoppingCart.model";

const createShoppingCart = ({ commit, rootState }: any, customerType: CustomerType) => {
	commit("activateOverlay", "sale.steps.step3.saving");
	return new Promise((resolve, reject) => {
		const apartmentId = rootState.saleProcess.sale.apartment.apartmentId;

		ShoppingCartService.create(apartmentId, customerType)
			.then((response) => {
				commit("updateShoppingCartId", response.data.id);
				resolve(response);
			})
			.catch(reject)
			.finally(() => commit("desactivateOverlay"));
	});
};

const getAvailableProducts = ({ commit, rootState }: any) => {
	commit("updateSummary", undefined);
	return new Promise((resolve, reject) => {
		const apartmentId = rootState.saleProcess.sale.apartment.apartmentId;
		const customerType = rootState.saleProcess.sale.customer.customerType;
		ShoppingCartService.getAvailableProducts(apartmentId, customerType)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getDefaultSummary = ({ commit }: any, { tmpShoppingCartId, selectedProduct }: any) => {
	commit("updateShoppingCartLoading", true);
	return new Promise((resolve, reject) => {
		ShoppingCartService.getDefaultSummary(tmpShoppingCartId, selectedProduct)
			.then((response) => {
				commit("updateSummary", response.data.summary);
				commit("updateSelectedBundle", selectedProduct);
				resolve(response.data.shoppingCart.products);
			})
			.catch((error) => {
				commit("updateSummary", undefined);
				reject(error);
			})
			.finally(() => {
				commit("updateShoppingCartLoading", false);
			});
	});
};

const getSummary = ({ commit, state }: any) => {
	commit("activateOverlay", "sale.steps.step4.refresh_cart");
	commit("updateShoppingCartLoading", true);
	return new Promise((resolve, reject) => {
		const shoppingCartId = state.shoppingCartId;
		ShoppingCartService.getSummary(shoppingCartId)
			.then((response) => {
				commit("updateSummary", response.data);
				resolve(response.data);
			})
			.catch(reject)
			.finally(() => {
				commit("updateShoppingCartLoading", false);
			});
	});
};

const updateSummary = ({ commit }: any, summary: ShoppingCart) => {
	commit("updateSummary", summary);
};

// TODO - CMS - Change getSummary for this.
const getShoppingCartSummary = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ShoppingCartService.getSummary(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const saveShoppingCartBundle = ({ commit, rootState }: any, products: any) => {
	return new Promise((resolve, reject) => {
		const apartmentId = rootState.saleProcess.sale.apartment.apartmentId;
		const customerType = rootState.saleProcess.sale.customer.customerType;
		ShoppingCartService.create(apartmentId, customerType, products)
			.then((response) => {
				commit("updateShoppingCartId", response.data.id);
				resolve(response.data.id);
			})
			.catch(reject);
	});
};

const getExtras = ({ commit, state }: any) => {
	return new Promise((resolve, reject) => {
		const shoppingCartId = state.shoppingCartId;
		ShoppingCartService.getExtras(shoppingCartId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateExtras = ({ commit, state, dispatch }: any, extras: any) => {
	commit("activateOverlay", "sale.steps.step4.refresh_cart");
	commit("updateShoppingCartLoading", true);
	return new Promise((resolve, reject) => {
		const shoppingCartId = state.shoppingCartId;
		ShoppingCartService.putExtras(shoppingCartId, extras)
			.then((response) => {
				commit("updateShoppingCartId", response.data.id);
				return dispatch("getSummary").then(() => resolve(response.data.id));
			})
			.catch(reject)
			.finally(() => {
				commit("desactivateOverlay");
				commit("updateShoppingCartLoading", false);
			});
	});
};

const getConfiguration = ({ commit, state }: any) => {
	commit("updateShoppingCartLoading", true);
	return new Promise((resolve, reject) => {
		const shoppingCartId = state.shoppingCartId;
		ShoppingCartService.getConfiguration(shoppingCartId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject)
			.finally(() => {
				commit("updateShoppingCartLoading", false);
			});
	});
};

const getNewExtrasConfiguration = ({ commit }: any, shoppingCartId: string) => {
	return new Promise((resolve, reject) => {
		ShoppingCartService.getConfiguration(shoppingCartId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateConfiguration = ({ commit, state, dispatch }: any, configuration: any) => {
	commit("updateShoppingCartLoading", true);
	return new Promise((resolve, reject) => {
		const shoppingCartId = state.shoppingCartId;
		ShoppingCartService.putConfiguration(shoppingCartId, configuration)
			.then((response) => {
				commit("updateShoppingCartId", response.data.id);
				return dispatch("getSummary").then(() => resolve(response.data));
			})
			.catch(reject)
			.finally(() => {
				commit("updateShoppingCartLoading", false);
			});
	});
};

const updateNewExtrasConfiguration = ({ commit }: any, payload: any) => {
	return new Promise((resolve, reject) => {
		ShoppingCartService.putConfiguration(payload.shoppingCartId, payload.configuration)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updatePromotions = ({ commit, dispatch }: any, payload: { shoppingCartId: string; bundles: any[] }) => {
	commit("activateOverlay", "sale.steps.step4.refresh_cart");
	commit("updateShoppingCartLoading", true);
	return new Promise((resolve, reject) => {
		ShoppingCartService.putPromotions(payload)
			.then((data) => {
				commit("updateShoppingCartId", data.id);
				return dispatch("getSummary").then(() => resolve(data));
			})
			.catch(reject)
			.finally(() => {
				commit("desactivateOverlay");
				commit("updateShoppingCartLoading", false);
			});
	});
};

const updateContracts = ({ commit, state, dispatch }: any, payload: { shoppingCartId: string; bundles: any[] }) => {
	commit("activateOverlay", "sale.steps.step6.saving");
	return new Promise((resolve, reject) => {
		ShoppingCartService.putContracts(payload)
			.then((data) => {
				commit("updateShoppingCartId", data.id);
				return dispatch("getSummary").then(() => resolve(data));
			})
			.catch(reject)
			.finally(() => commit("desactivateOverlay"));
	});
};

const getPromotionsAndContracts = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ShoppingCartService.getPromotionsAndContracts(id)
			.then((data) => {
				resolve(data);
			})
			.catch(reject);
	});
};

// TODO - CMS - Temporary name
const updateExtrasV1 = ({ commit }: any, payload: any) => {
	return new Promise((resolve, reject) => {
		ShoppingCartService.putExtras(payload.shoppingCartId, payload.extras)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const desactivateShoppingCartLoadingWithOverlay = ({ commit, getters }: any) => {
	commit("updateShoppingCartLoading", false);

	const { active: overlayActive } = getters.getOverlay;
	if (overlayActive) {
		commit("desactivateOverlay");
	}
};

export default {
	createShoppingCart,
	desactivateShoppingCartLoadingWithOverlay,
	getAvailableProducts,
	getConfiguration,
	getDefaultSummary,
	getExtras,
	getNewExtrasConfiguration,
	getPromotionsAndContracts,
	getShoppingCartSummary,
	getSummary,
	saveShoppingCartBundle,
	updateConfiguration,
	updateContracts,
	updateExtras,
	updateExtrasV1,
	updateNewExtrasConfiguration,
	updatePromotions,
	updateSummary
};
