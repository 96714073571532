import { SaleProcessService } from "@/api/services/saleProcess.service";
import { SaleProcessStatus } from "@/api/constants/enums";
import { TrackingFilter } from "@/api/models/tracking/trackingFilter.model";
import { downloadFile, openFile } from "@/utils/file.util";
import { AxiosResponse } from "axios";

const loadSaleProcess = ({ commit }: any, saleProcess: any) => {
	return new Promise((resolve) => {
		commit("updateId", saleProcess.id);
		commit("updateStatus", saleProcess.status);
		commit("updateFirstName", saleProcess.customer.firstName);
		commit("updatePhoneNumber", saleProcess.phone);
		commit("updateCoverageSearchTerm", saleProcess.addressSearch?.search);
		if (saleProcess.addressSearch.googleLatitude) {
			commit("setAddressSearchedCoords", {
				lat: Number(saleProcess.addressSearch.googleLatitude),
				lng: Number(saleProcess.addressSearch.googleLongitude)
			});
		} else {
			commit("setAddressSearchedCoords", {
				lat: -1,
				lng: -1
			});
		}
		commit("updateCoverageSearchType", saleProcess.addressSearch?.searchType);
		commit("updateAddressAndApartment", {
			addressId: saleProcess.addressSearch?.coberturaId,
			apartmentId: saleProcess.addressSearch?.coberturaPisoPortaId
		});
		commit("updateCustomer", saleProcess);
		commit("updateSelectedBundle", saleProcess.selectedBundle);
		resolve(true);
	});
};

const createSaleProcess = ({ commit }: any, payload: any) => {
	commit("activateOverlay", "sale.steps.step3.saving");
	return new Promise((resolve, reject) => {
		SaleProcessService.create(payload.firstName, payload.phoneNumber, payload.campaign, payload.callType)
			.then((response) => {
				if (response.data.id) {
					commit("updateId", response.data.id);
				}
				commit("saleProcessCreated", payload);
				commit("updateStatus", SaleProcessStatus.FRESH);
				resolve(response);
			})
			.catch(reject)
			.finally(() =>
				setTimeout(() => {
					commit("desactivateOverlay");
				}, 1000)
			);
	});
};

const deleteSaleProcess = ({ commit }: any, id: any) => {
	commit("activateOverlay", "tracking.dialog_actions.deleting");
	return new Promise((resolve, reject) => {
		SaleProcessService.delete(id)
			.then((response) => {
				resolve(response.status);
			})
			.catch(reject)
			.finally(() =>
				setTimeout(() => {
					commit("desactivateOverlay");
				}, 1000)
			);
	});
};

const selectCustomerType = ({ commit, state }: any, saleProcess: any) => {
	commit("activateOverlay", "sale.steps.step3.saving");
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.patchSaleProcess(saleProcessId, saleProcess)
			.then((response) => {
				commit("updateStatus", saleProcess.status);
				commit("updateCustomer", saleProcess);
				resolve(response.data);
			})
			.catch(reject)
			.finally(() => {
				commit("desactivateOverlay");
			});
	});
};

const validateCustomerType = ({ commit, state }: any, { behaviour, saleProcessId }: { behaviour: string; saleProcessId: number }) => {
	commit("activateOverlay", "sale.steps.step3.saving");
	return new Promise((resolve, reject) => {
		const saleProcess = {
			status: SaleProcessStatus.CUSTOMER_TYPE_VALIDATED,
			customerAccountBehaviour: behaviour
		};

		SaleProcessService.patchSaleProcess(saleProcessId, saleProcess)
			.then((response) => {
				commit("updateStatus", saleProcess.status);
				resolve(response.status);
			})
			.catch(reject)
			.finally(() => {
				commit("desactivateOverlay");
			});
	});
};

const getProcessById = ({ commit }: any, id: number) => {
	commit("activateOverlay", "sale.steps.step8.loading");
	commit("updateShoppingCartLoading", true);
	return new Promise((resolve, reject) => {
		SaleProcessService.getProcessById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject)
			.finally(() => {
				commit("updateShoppingCartLoading", false);
			});
	});
};

const getProcessByPhone = ({ commit }: any, phone: number) => {
	commit("activateOverlay", "sale.steps.step8.loading");
	return new Promise((resolve, reject) => {
		SaleProcessService.getProcessByPhone(phone)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject)
			.finally(() => {
				commit("desactivateOverlay");
			});
	});
};

const updateSaleProcess = ({ commit, state }: any, saleProcess: any) => {
	commit("activateOverlay", "sale.steps.step8.saving");
	commit("updateShoppingCartLoading", true);
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.patchSaleProcess(saleProcessId, saleProcess)
			.then(({ data }: AxiosResponse) => {
				resolve(data);
			})
			.catch(reject)
			.finally(() => {
				commit("updateShoppingCartLoading", false);
				commit("desactivateOverlay");
			});
	});
};

const sendContract = ({ commit }: any, saleProcessId: string) => {
	commit("activateOverlay", "sale.steps.step8.sending_contract");
	return new Promise((resolve, reject) => {
		SaleProcessService.sendContract(saleProcessId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject)
			.finally(() => {
				setTimeout(() => {
					commit("desactivateOverlay");
				}, 1000);
			});
	});
};

const sendSms = ({ commit }: any, saleProcessId: string) => {
	commit("activateOverlay", "sale.steps.step8.sending_sms");
	return new Promise((resolve, reject) => {
		SaleProcessService.sendSms(saleProcessId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject)
			.finally(() => {
				setTimeout(() => {
					commit("desactivateOverlay");
				}, 1000);
			});
	});
};

const sendDigitel = ({ commit }: any, saleProcessId: string) => {
	commit("activateOverlay", "sale.steps.step8.sending_digitel");
	return new Promise((resolve, reject) => {
		SaleProcessService.sendDigitel(saleProcessId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject)
			.finally(() => {
				setTimeout(() => {
					commit("desactivateOverlay");
				}, 1000);
			});
	});
};

// Additional Contacts
const getAdditionalContacts = ({ commit, state }: any) => {
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.getAdditionalContacts(saleProcessId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const saveAdditionalContact = ({ commit, state }: any, additionalContact: any) => {
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.postAdditionalContact(saleProcessId, additionalContact)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateAdditionalContact = ({ commit, state }: any, additionalContact: any) => {
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.putAdditionalContact(saleProcessId, additionalContact)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const deleteAdditionalContact = ({ commit, state }: any, additionalContactId: string) => {
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.deleteAdditionalContact(saleProcessId, additionalContactId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

// Documents
const getDocuments = ({ commit, state }: any) => {
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.getDocuments(saleProcessId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getDocument = ({ commit, state }: any, payload) => {
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.getDocument(saleProcessId, payload.fileName)
			.then((response) => {
				openFile(response.data, payload.type);
				resolve(response.data);
			})
			.catch(reject);
	});
};

const saveDocument = ({ commit, state }: any, document: any) => {
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.postDocument(saleProcessId, document)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateDocument = ({ commit, state }: any, document: any) => {
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.putDocument(saleProcessId, document)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const deleteDocument = ({ commit, state }: any, documentId: string) => {
	return new Promise((resolve, reject) => {
		const saleProcessId = state.id;
		SaleProcessService.deleteDocument(saleProcessId, documentId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

// Dashboard

const getDashboardInfo = ({ commit }: any, filters: TrackingFilter) => {
	return new Promise((resolve, reject) => {
		SaleProcessService.getDashboardInformation(filters)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getDashboardCsv = ({ commit }: any, filters: any) => {
	return new Promise((resolve, reject) => {
		SaleProcessService.getDashboardCsv(filters)
			.then((response) => {
				downloadFile(response.data, "dashboard-leads.csv", "text/csv");
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getLeadHistoryCsv = ({ commit }: any, id: any) => {
	return new Promise((resolve, reject) => {
		SaleProcessService.getLeadHistoryCsv(id)
			.then((response) => {
				downloadFile(response.data, `lead-${id}-history.csv`, "text/csv");
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getDashboardStats = () => {
	return new Promise((resolve, reject) => {
		Promise.all([
			SaleProcessService.getDashboardStatsSMSAnswered(),
			SaleProcessService.getDashboardStatsSMSCertification(),
			SaleProcessService.getDashboardStatsCompletedToday(),
			SaleProcessService.getDashboardStatsCompletedThisMonth(),
			SaleProcessService.getDashboardStatsPresalesToday(),
			SaleProcessService.getDashboardStatsPresalesThisMonth()
		])
			.then((res) => {
				const formattedData = res.map((response, index) => {
					let status;
					if (index === 0) {
						status = "sms-answered";
					} else if (index === 1) {
						status = "sms-certification";
					} else if (index === 2 || index === 3) {
						status = "completed";
					} else {
						status = "presales";
					}
					return {
						total: response.data.count,
						status: status
					};
				});
				resolve(formattedData);
			})
			.catch(reject);
	});
};

export default {
	loadSaleProcess,
	createSaleProcess,
	deleteSaleProcess,
	selectCustomerType,
	validateCustomerType,
	updateSaleProcess,
	sendContract,
	sendSms,
	sendDigitel,
	getProcessById,
	getProcessByPhone,
	getAdditionalContacts,
	saveAdditionalContact,
	updateAdditionalContact,
	deleteAdditionalContact,
	getDocuments,
	getDocument,
	saveDocument,
	updateDocument,
	deleteDocument,
	getDashboardInfo,
	getDashboardCsv,
	getLeadHistoryCsv,
	getDashboardStats
};
