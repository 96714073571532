import { ShoppingCart, ShoppingCartBundle, ShoppingCartPrice } from "@/api/models/shoppingCart.model";

interface ShoppingCartState {
	shoppingCartId: string;
	shoppingCartLoading: boolean;
	summary: ShoppingCart;
}

export const shoppingCartInitialState = () => {
	return {
		shoppingCartId: "",
		shoppingCartLoading: false,

		summary: {
			bundles: [] as ShoppingCartBundle[],
			prices: [] as ShoppingCartPrice[],
			contract: {
				name: "",
				months: -1
			},
			parameters: {
				customerType: ""
			}
		} as ShoppingCart
	};
};

const state: ShoppingCartState = shoppingCartInitialState();
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
	state,
	actions,
	mutations,
	getters
};
