import { ProductModificationProcessService } from "@/api/services";
import { ProductModificationProcess } from "@/api/models/customer";

const createProductModificationProcess = ({ commit }: any, productModificationProcess: ProductModificationProcess) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.create({ productModificationProcess })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getProductModificationById = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.getById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateProductModification = ({ commit }: any, payload: any) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.update(payload.id, payload.data)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const sendProductModification = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.send(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const saveProductModification = ({ commit }: any, productModification: any) => {
	commit("setProductModification", productModification);
};

const saveProductModificationShoppingCartId = ({ commit }: any, shoppingCartId: string) => {
	commit("setProductModificationShoppingCartId", shoppingCartId);
};

const saveProductModificationStatus = ({ commit }: any, status: string) => {
	commit("setProductModificationStatus", status);
};

const getProductModificationPromotionsAndContracts = (
	{ commit }: any,
	{ productId, shoppingCartId }: { productId: string; shoppingCartId: string }
) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.getModificationProcessPromotionsAndContracts({ productId, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateModificationProcessPromotions = ({ commit }: any, payload: { productId: string; shoppingCartId: string; bundles: any[] }) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.putModificationProcessPromotions(payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateModificationProcessContracts = ({ commit }: any, payload: { productId: string; shoppingCartId: string; bundles: any[] }) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.putModificationProcessContracts(payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getProductModificationConfiguration = ({ commit }: any, { productId, shoppingCartId }: { productId: string; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.getModificationProcessConfiguration({ productId, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateModificationProcessConfiguration = (
	{ commit }: any,
	payload: { productId: string; shoppingCartId: string; bundles: any[]; parameters: any[] }
) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.putModificationProcessConfiguration(payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getModificationProcessSummary = ({ commit }: any, { productId, shoppingCartId }: { productId: string; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductModificationProcessService.getModificationProcessSummary({ productId, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

export default {
	createProductModificationProcess,
	getProductModificationById,
	updateProductModification,
	sendProductModification,
	saveProductModification,
	saveProductModificationShoppingCartId,
	saveProductModificationStatus,
	getProductModificationPromotionsAndContracts,
	updateModificationProcessPromotions,
	updateModificationProcessContracts,
	getProductModificationConfiguration,
	updateModificationProcessConfiguration,
	getModificationProcessSummary
};
