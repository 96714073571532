import { newExtrasProcessInitialState } from "./newExtrasProcess";

const setNewExtras = (state: any, newExtras: any) => {
	state.newExtras = newExtras;
};

const setNewExtrasShoppingCartId = (state: any, shoppingCartId: string) => {
	state.shoppingCartId = shoppingCartId;
};

const setNewExtrasStatus = (state: any, status: string) => {
	state.status = status;
};

const clearNewExtrasProcess = (state: any) => {
	Object.assign(state, newExtrasProcessInitialState());
};

export default {
	setNewExtras,
	setNewExtrasShoppingCartId,
	setNewExtrasStatus,
	clearNewExtrasProcess
};
