import { TransitionCheck } from "@/api/models/product-transition/productTransition.model";
import { productModificationBundleToProductModificationBundleAPI } from "../product-modification/productModification.adapter";

export const transitionCheckToTransitionCheckAPI = (transition: TransitionCheck) => {
	return {
		shoppingCartId: transition.shoppingCartId,
		transition: transition.transition,
		bundles: transition.bundles.map(productModificationBundleToProductModificationBundleAPI)
	};
};
