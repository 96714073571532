const desactivateOverlayWithShoppingCartLoading = ({ commit, getters }: any) => {
	const shoppingCartLoading = getters.getShoppingCartLoading;
	if (!shoppingCartLoading) {
		commit("desactivateOverlay");
	}
};

export default {
	desactivateOverlayWithShoppingCartLoading
};
