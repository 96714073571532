import { v4 as uuidv4 } from "uuid";
import { DateFormatType } from "@/api/constants/enums";
import dayjs from "dayjs";

const setStateGeneralDialog = (state: any, { opened, i18n, parameters, message, messages, source, mode, status }) => {
	state.generalDialog = {
		uuid: uuidv4(),
		i18n,
		parameters,
		source,
		message,
		messages,
		mode,
		status,
		opened
	};
};

const setStateOverlay = (state: any, { active, text }) => {
	state.overlay = {
		active,
		text
	};
};

const activateDialog = (state: any, { i18n, parameters, message, messages, source, mode, status }) => {
	if (parameters) {
		if (parameters.date) {
			parameters.date = dayjs(parameters.date).format(DateFormatType.LONG_DATE_TIME);
		}
	}

	setStateGeneralDialog(state, { opened: true, i18n, parameters, message, messages, source, mode, status });
};

const desactivateDialog = (state: any) => {
	setStateGeneralDialog(state, { opened: false, i18n: "", parameters: undefined, message: "", messages: [], source: "", mode: "", status: 0 });
};

const activateOverlay = (state: any, text: string) => {
	setStateOverlay(state, { active: true, text });
};

const desactivateOverlay = (state: any) => {
	setStateOverlay(state, { active: false, text: "" });
};

const updateStepperStep = (state: any, step: number) => {
	state.stepper.step = step;
};

export default {
	activateDialog,
	desactivateDialog,
	activateOverlay,
	desactivateOverlay,
	updateStepperStep
};
