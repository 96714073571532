import { Configuration } from "@/api/models/configuration.model";

export const configurationToConfigurationAPI = (configuration: Configuration) => ({
	bundles: bundlesToBundlesAPI(configuration.bundles),
	parameters: configuration.parameters
});

const bundlesToBundlesAPI = (bundles: any[]) => {
	return bundles.map((bundle) => {
		return {
			id: bundle.id,
			name: bundle.name,
			products: productsToProductsAPI(bundle.products)
		};
	});
};

const productsToProductsAPI = (products: any[]) => {
	return products.map((product) => {
		const { id, name, type, characteristics = [], characteristicGroups = [], serviceSpecification = [] } = product;

		return {
			id,
			name,
			type,
			characteristics: productCharacteristicsToProductCharacteristicsAPI(characteristics, characteristicGroups),
			serviceSpecification: serviceSpecificationToServiceSpecificationAPI(serviceSpecification)
		};
	});
};

const productCharacteristicsToProductCharacteristicsAPI = (characteristics: any[], characteristicGroups: any[]) => {
	const formattedCharacteristics = characteristics.map((characteristic: any) => characteristicToCharacteristicAPI(characteristic));
	const formattedCharacteristicGroups = characteristicGroups.map((group) => {
		return group.characteristics.map((characteristic) => characteristicToCharacteristicAPI(characteristic));
	});

	return [...formattedCharacteristics, ...formattedCharacteristicGroups.flat()];
};

const characteristicToCharacteristicAPI = (characteristic: any) => ({
	name: characteristic.name,
	qty: characteristic.qty
});

const serviceSpecificationToServiceSpecificationAPI = (serviceSpecification: any) => {
	return serviceSpecification
		.filter((specification) => !specification.hidden)
		.map((specification: any) => {
			const { name, type, value } = specification;
			const valueParsed = type === "STRING" ? ((value || "").length > 0 ? value : undefined) : value;
			return {
				name,
				type,
				value: valueParsed
			};
		});
};
