import { Route } from "vue-router";

const authGuard = (to: Route, from: Route, next: (redirect?: string) => void) => {
	const isAuthenticated = localStorage.getItem("token");
	if (isAuthenticated) {
		next();
	} else {
		next("/login");
	}
};

const authLoginGuard = (to: Route, from: Route, next: (redirect?: string) => void) => {
	const isAuthenticated = localStorage.getItem("token");
	if (isAuthenticated) {
		next("/");
	} else {
		next();
	}
};

export { authGuard, authLoginGuard };
