import { Apartment } from "@/api/models/address/apartment.model";

interface ApartmentState {
	apartment: Apartment;
}

export const apartmentInitialState = () => {
	return {
		apartment: {
			apartmentId: "-1",
			floor: "",
			door: "",
			stair: "",
			network: ""
		} as Apartment
	};
};

const state: ApartmentState = apartmentInitialState();
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
	state,
	actions,
	mutations,
	getters
};
