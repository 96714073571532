<template>
	<v-navigation-drawer v-model="drawer" app touchless permanent mini-variant>
		<v-layout column fill-height>
			<v-list dense nav class="pa-0">
				<v-list-item class="white-bg">
					<v-list-item-content>
						<v-list-item-title class="d-flex justify-center align-center">
							<v-img class="flex-grow-1 px-1 py-2" contain width="60" max-height="40" src="../assets/img/logo-simple.svg"></v-img>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list dense nav class="pa-0 nav-menu">
				<div class="d-flex justify-end pa-6 pb-2">
					<v-btn icon v-if="!mini">
						<v-icon large color="white">mdi-chevron-left</v-icon>
					</v-btn>
				</div>
				<span v-for="item in navItems" :key="item.title">
					<span v-if="showNavLink(item)">
						<v-list-item class="px-3" link :to="item.path" :disabled="isLoading || (currentRoute.startsWith('/sale/') && item.path === '/sale')">
							<v-tooltip right>
								<template v-slot:activator="{ on, attrs }">
									<v-list-item-icon v-bind="attrs" v-on="on">
										<v-icon>{{ item.icon }}</v-icon>
									</v-list-item-icon>
								</template>
								<span>{{ $t(item.title || "") }}</span>
							</v-tooltip>

							<v-list-item-content>
								<v-list-item-title>{{ $t(item.title || "") }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</span>
				</span>
			</v-list>

			<v-spacer></v-spacer>
			<v-list dense nav class="black-bg">
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-list-item v-bind="attrs" v-on="on" class="px-2" link @click.stop="logout" :disabled="isLoading">
							<v-list-item-icon>
								<v-icon>mdi-logout-variant</v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title>{{ $t("login.logout") }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span>{{ $t("login.logout") }}</span>
				</v-tooltip>
			</v-list>
		</v-layout>
	</v-navigation-drawer>
</template>

<script lang="ts">
import Vue from "vue";
import { UIPAGE } from "@/constants/ui-page.constants";
import { filterRoutes } from "@/router/index";
import { permissionCanActivate } from "@/router/guards/permissionGuard";
import { RouteInfo } from "@/router/types";

export default Vue.extend({
	name: "NavMenu",
	components: {},
	data: () => ({
		drawer: true,
		mini: true,
		navItems: [] as RouteInfo[]
	}),
	mounted() {
		const nav = [
			UIPAGE.DASBOARD,
			UIPAGE.SALE,
			UIPAGE.TRACKING_LEADS,
			UIPAGE.TRACKING_SALES,
			UIPAGE.COVERAGE_SEARCH,
			UIPAGE.APPROVALS,
			UIPAGE.CUSTOMERS,
			UIPAGE.ADMINISTRATION
		];
		this.navItems = filterRoutes(...nav);
	},
	methods: {
		logout() {
			this.$store.dispatch("logout");
			this.$router.push("/login", () => {});
			if (this.$route.matched[0].name === "sale") return;
		},
		showNavLink({ id }: RouteInfo) {
			const permissionGuard = permissionCanActivate({ routeId: id });
			return permissionGuard;
		}
	},
	computed: {
		isLoading(): boolean {
			return this.$store.state.components.overlay.active;
		},
		currentRoute(): string {
			return this.$route.path;
		}
	}
});
</script>
<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.black-bg {
	background-color: black !important;
	color: white;
}

.white-bg {
	background-color: white;
	border-radius: 0;
}

.spacer {
	background-color: black;
}
.nav-menu {
	background-color: black !important;
	border-top-right-radius: var(--adm-border-radius) !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
	color: rgba(255, 255, 255, 0.45) !important;
}

.v-list-item--disabled {
	color: white !important;
	.v-list-item__icon {
		.v-icon {
			color: white !important;
		}
	}
}

.theme--light.v-icon {
	color: rgba(255, 255, 255, 0.45);
}

.v-list .v-list-item--active {
	color: white;
}

.v-list .v-list-item--active .theme--light.v-icon {
	color: white;
}

.v-navigation-drawer__border {
	background-color: transparent !important;
}
</style>
<style>
.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
	background-color: rgba(0, 0, 0, 0) !important;
}
</style>
