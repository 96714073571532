export enum UIPAGE {
	ADMINISTRATION = "/UI/PAGE/administration",
	ADMINISTRATION_B2B_SEGMENTS = "/UI/PAGE/administration/b2b-segments",
	ADMINISTRATION_BLACKLIST = "/UI/PAGE/administration/blacklist",
	ADMINISTRATION_BULK = "/UI/PAGE/administration/bulk",
	ADMINISTRATION_CACHE = "/UI/PAGE/administration/cache",
	ADMINISTRATION_CHANNELS = "/UI/PAGE/administration/channels",
	ADMINISTRATION_DEPENDENCIES = "/UI/PAGE/administration/dependencies",
	ADMINISTRATION_DROPDOWNS = "/UI/PAGE/administration/dropdowns",
	ADMINISTRATION_OTHERS = "/UI/PAGE/administration/others",
	ADMINISTRATION_PRODUCTS_MANDATORY_DOCUMENTS = "/UI/PAGE/administration/products-mandatory-documents",
	ADMINISTRATION_PROMOTION_GROUPS = "/UI/PAGE/administration/promotion-groups",
	ADMINISTRATION_RETAIL_EXCLUSIVITY_RULES = "/UI/PAGE/administration/retail-exclusivity-rules",
	ADMINISTRATION_TRANSITION_CONFIGURATION = "/UI/PAGE/administration/products-transition",
	ADMINISTRATION_USERS = "/UI/PAGE/administration/users",
	APPROVAL_DETAIL = "/UI/PAGE/approvals/:id",
	APPROVALS = "/UI/PAGE/approvals",
	COVERAGE_SEARCH = "/UI/PAGE/coverage-search",
	CUSTOMER_360 = "/UI/PAGE/customer",
	CUSTOMER_360_CHARGES_CREDITS = "/UI/PAGE/customer/charges-credits",
	CUSTOMER_360_FINANCES = "/UI/PAGE/customer/finances",
	CUSTOMER_360_FOLLOW_UP = "/UI/PAGE/customer/follow-up",
	CUSTOMER_360_NOTES = "/UI/PAGE/customer/notes",
	CUSTOMER_360_ORDERS = "/UI/PAGE/customer/orders",
	CUSTOMER_360_PRODUCTS = "/UI/PAGE/customer/products",
	CUSTOMER_360_TASKS = "/UI/PAGE/customer/tasks",
	CUSTOMER_NEW_EXTRAS = "/UI/PAGE/customer/new-extras",
	CUSTOMER_NEW_EXTRAS_BUNDLE = "/UI/PAGE/customer/new-extras/bundle",
	CUSTOMER_NEW_EXTRAS_ID_BUNDLE = "/UI/PAGE/customer/new-extras/:newExtrasId/bundle",
	CUSTOMER_NEW_EXTRAS_CONFIGURATION = "/UI/PAGE/customer/new-extras/configuration",
	CUSTOMER_NEW_EXTRAS_ORDER = "/UI/PAGE/customer/new-extras/order",
	CUSTOMER_NEW_EXTRAS_PROMOTIONS = "/UI/PAGE/customer/new-extras/promotions",
	CUSTOMER_PRODUCT_MODIFICATION = "/UI/PAGE/customer/product/modification",
	CUSTOMER_PRODUCT_MODIFICATION_BUNDLE = "/UI/PAGE/customer/product/modification/bundle",
	CUSTOMER_PRODUCT_MODIFICATION_CONFIGURATION = "/UI/PAGE/customer/product/modification/configuration",
	CUSTOMER_PRODUCT_MODIFICATION_ID_BUNDLE = "/UI/PAGE/customer/product/modification/:modificationId/bundle",
	CUSTOMER_PRODUCT_MODIFICATION_ORDER = "/UI/PAGE/customer/product/modification/order",
	CUSTOMER_PRODUCT_MODIFICATION_PROMOTIONS = "/UI/PAGE/customer/product/modification/promotions",
	CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION = "/UI/PAGE/customer/product/only-mobiles-transition",
	CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_BUNDLE = "/UI/PAGE/customer/product/only-mobiles-transition/bundle",
	CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_ID_BUNDLE = "/UI/PAGE/customer/product/only-mobiles-transition/:transitionId/bundle",
	CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_CONFIGURATION = "/UI/PAGE/customer/product/only-mobiles-transition/configuration",
	CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_ORDER = "/UI/PAGE/customer/product/only-mobiles-transition/order",
	CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_PROMOTIONS = "/UI/PAGE/customer/product/only-mobiles-transition/promotions",
	CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION = "/UI/PAGE/customer/product/shared-mobiles-transition",
	CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_BUNDLE = "/UI/PAGE/customer/product/shared-mobiles-transition/bundle",
	CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_ID_BUNDLE = "/UI/PAGE/customer/product/shared-mobiles-transition/:transitionId/bundle",
	CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_CONFIGURATION = "/UI/PAGE/customer/product/shared-mobiles-transition/configuration",
	CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_ORDER = "/UI/PAGE/customer/product/shared-mobiles-transition/order",
	CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_PROMOTIONS = "/UI/PAGE/customer/product/shared-mobiles-transition/promotions",
	CUSTOMER_PRODUCT_TERMINATION = "/UI/PAGE/customer/product/termination",
	CUSTOMER_PRODUCT_TRANSITION = "/UI/PAGE/customer/product/transition",
	CUSTOMER_PRODUCT_TRANSITION_BUNDLE = "/UI/PAGE/customer/product/transition/bundle",
	CUSTOMER_PRODUCT_TRANSITION_ID_BUNDLE = "/UI/PAGE/customer/product/transition/:transitionId/bundle",
	CUSTOMER_PRODUCT_TRANSITION_CONFIGURATION = "/UI/PAGE/customer/product/transition/configuration",
	CUSTOMER_PRODUCT_TRANSITION_ORDER = "/UI/PAGE/customer/product/transition/order",
	CUSTOMER_PRODUCT_TRANSITION_PROMOTIONS = "/UI/PAGE/customer/product/transition/promotions",
	CUSTOMERS = "/UI/PAGE/customers",
	DASBOARD = "/UI/PAGE/dashboard",
	LOGIN = "/UI/PAGE/login",
	SALE = "/UI/PAGE/sale",
	SALE_BUNDLE = "/UI/PAGE/sale/bundle",
	SALE_CONFIGURATION = "/UI/PAGE/sale/configuration",
	SALE_CONTRACT = "/UI/PAGE/sale/contract",
	SALE_COVERAGE = "/UI/PAGE/sale/coverage",
	SALE_CUSTOMER = "/UI/PAGE/sale/customer",
	SALE_EXTRAS = "/UI/PAGE/sale/extras",
	SALE_IDENTIFICATION = "/UI/PAGE/sale/identification",
	SALE_PROMOTIONS = "/UI/PAGE/sale/promotions",
	TRACKING_LEADS = "/UI/PAGE/tracking-leads",
	TRACKING_SALES = "/UI/PAGE/tracking-sales"
}
