export enum ColorStatusType {
	BLACK = "var(--adm-status-black)",
	GREEN = "var(--adm-status-green)",
	GREEN_LIGHT = "var(--adm-status-green-light)",
	GREY = "var(--adm-status-grey)",
	GREY_LIGHT = "var(--adm-status-grey-light)",
	ORANGE = "var(--adm-status-orange)",
	ORANGE_LIGHT = "var(--adm-status-orange-light)",
	PURPLE = "var(--adm-status-purple)",
	PURPLE_LIGHT = "var(--adm-status-purple-light)",
	RED = "var(--adm-status-red)",
	RED_LIGHT = "var(--adm-status-red-light)",
	WHITE = "var(--adm-status-white)"
}

export enum ColorType {
	GREY = "var(--adm-grey)",
	GREY_DARK = "var(--adm-grey-dark)",
	PURPLE_LIGHT = "var(--adm-purple-light)",
	PURPLE = "var(--adm-purple)",
	WHITE = "white"
}
