<template>
	<v-app class="app-container">
		<nav-menu v-if="isAuthenticated"></nav-menu>
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script lang="ts">
import Vue from "vue";
import NavMenu from "./components/NavMenu.vue";

export default Vue.extend({
	name: "App",
	components: {
		NavMenu
	},
	mounted() {
		document.body.classList.remove("bootstrap-loading");
		this.$store.commit("desactivateDialog");
	},
	computed: {
		isAuthenticated(): boolean {
			return this.$store.state.user.logged;
		}
	}
});
</script>
<style scoped>
.app-container {
	overflow: hidden;
}
</style>
