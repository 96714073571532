import { ProductTransitionConfiguration, ProductTransitionConfigurationAPI } from "@/api/models/admin/productTransitionConfiguration.model";

export const transitionConfigurationAPIToTransitionConfiguration = (
	configuration: ProductTransitionConfigurationAPI
): ProductTransitionConfiguration => ({
	id: configuration.id,
	product: configuration.product,
	createdBy: configuration.created.by,
	createdAt: configuration.created.at,
	updatedBy: configuration.updated.by,
	updatedAt: configuration.updated.at,
	characteristics: configuration.configuration?.characteristics ?? []
});

export const transitionConfigurationToTransitionConfigurationAPI = (
	configuration: ProductTransitionConfiguration
): Partial<ProductTransitionConfigurationAPI> => ({
	id: configuration.id,
	product: configuration.product,
	configuration: configuration.characteristics ? { characteristics: configuration.characteristics } : undefined
});
