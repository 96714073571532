interface UserState {
	logged: boolean;
	username: string;
	role: string;
	currentChannel: string;
	channels: string[];
}

export const userInitialState = () => {
	return {
		logged: false,
		username: "",
		role: "",
		currentChannel: "",
		channels: []
	};
};

const state: UserState = userInitialState();
import actions from "./actions";
import mutations from "./mutations";
export default {
	state,
	actions,
	mutations
};
