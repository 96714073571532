import { RouteInfo } from "./types";
import { authGuard, permissionGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewTrackingSales = () => import(/* webpackChunkName: "chunk-tracking-sales" */ "@/views/tracking-sales/TrackingSales.vue");

export const TrackingSalesRoute: RouteInfo = {
	id: UIPAGE.TRACKING_SALES,
	path: "/sales",
	component: ViewTrackingSales,
	guards: [authGuard, permissionGuard],
	title: "sales.display",
	icon: "mdi-cart-outline"
};
