enum CompanyType {
	COMPANY = "COMPANY",
	INDIVIDUAL = "INDIVIDUAL"
}

// TODO - CMS - Unify ProductType and ProductIconType in a const
enum ProductType {
	FIBRE = "FIBRE",
	VOIP = "VOIP",
	MOBILE = "MOBILE",
	TV = "TV",
	VOIP_CLOUDPBX = "VOIP_CLOUDPBX",
	VOIP_SIPTRUNK = "VOIP_SIPTRUNK",
	DIGITAL_SERVICES = "DIGITAL_SERVICES"
}

enum ProductIconType {
	FIBRE = "$router",
	VOIP = "$telephone",
	MOBILE = "$mobile",
	TV = "$tv",
	VOIP_CLOUDPBX = "$voipCloud",
	VOIP_SIPTRUNK = "$voipCloud",
	DIGITAL_SERVICES = "$digitalService"
}

enum FieldType {
	ADDRESS = "ADDRESS",
	STRING = "STRING",
	SELECT = "SELECT",
	PHONE_NUMBER = "PHONE_NUMBER",
	PORTIN_NUMBER = "PORTIN_NUMBER",
	ICCID = "ICCID",
	PORTIN_ICC = "PORTIN_ICC",
	MOBILE_PORTIN = "PORTIN",
	VOIP_CLOUDPBX_SID = "VOIP_CLOUDPBX_SID",
	VOIP_SIPTRUNK_SID = "VOIP_SIPTRUNK_SID",
	ADDITIONAL_PHONE_NUMBERS = "ADDITIONAL_PHONE_NUMBERS",
	LONGITUDE_V2 = "LONGITUDE_V2",
	LATITUDE = "LATITUDE",
	NOTES_FOR_INSTALLER = "NOTES_FOR_INSTALLER"
}

enum SaleProcessStatus {
	FRESH = "fresh",
	ADDRESS_SEARCH = "address-search",
	ADDRESS_SELECTED = "address-selected",
	APARTMENT_SELECTED = "apartment-selected",
	CUSTOMER_TYPE_SELECTED = "customer-type-selected",
	CUSTOMER_TYPE_VALIDATED = "customer-type-validated",
	BUNDLE_SELECTED = "bundle-selected",
	EXTRAS_SELECTED = "extras-selected",
	FEATURES_SELECTED = "features-selected",
	SHOPPING_CART_SAVED = "shopping-cart-saved",
	SHOPPING_CART_CONFIGURED = "shopping-cart-configured",
	CONTRACT_DATA_SAVED = "contract-data-saved",
	PENDING_IBAN_VALIDATION = "pending-iban-validation",
	IBAN_VALIDATION_FAILED = "iban-validation-failed",
	NO_SALE = "no-sale",
	NO_COVERAGE = "no-coverage"
}

enum ProductTransitionProcessStatus {
	FRESH = "fresh",
	TRANSITION_SELECTED = "transition-selected",
	FEATURES_SELECTED = "features-selected",
	SHOPPING_CART_CONFIGURED = "shopping-cart-configured",
	SHOPPING_CART_SAVED = "shopping-cart-saved",
	ORDER_DATA_SAVED = "order-data-saved"
}

enum ProductModificationProcessStatus {
	FRESH = "fresh",
	MODIFICATION_SELECTED = "modification-selected",
	FEATURES_SELECTED = "features-selected",
	SHOPPING_CART_CONFIGURED = "shopping-cart-configured",
	SHOPPING_CART_SAVED = "shopping-cart-saved",
	ORDER_DATA_SAVED = "order-data-saved"
}

enum CustomerNewExtrasProcessStatus {
	FRESH = "fresh",
	EXTRAS_SELECTED = "extras-selected",
	FEATURES_SELECTED = "features-selected",
	SHOPPING_CART_CONFIGURED = "shopping-cart-configured",
	SHOPPING_CART_SAVED = "shopping-cart-saved",
	ORDER_DATA_SAVED = "order-data-saved"
}

enum CustomerAccountBehaviour {
	PATCH = "PATCH",
	CREATE = "CREATE"
}

enum SearchType {
	ZIP = "ZIP",
	ID = "ID",
	GOOGLE = "GOOGLE",
	COORDS = "COORDS"
}

enum GooglePlaceDataType {
	ZIP = "postal_code",
	PROVINCE = "administrative_area_level_2",
	CITY = "locality"
}

enum GeneralDialogMode {
	ERROR = "ERROR",
	WARNING = "WARNING",
	SUCCESS = "SUCCESS"
}

enum CallType {
	INCOMING = "incoming",
	OUTCOMING = "outcoming"
}

enum DateFormatType {
	LONG_DATE_TIME = "DD-MM-YYYY HH:mm:ss",
	SHORT_DATE_TIME = "DD-MM-YYYY",
	DATEPICKER_LONG_DATE = "YYYY-MM-DD"
}

enum StatesFormType {
	CREATE = "CREATE",
	EDIT = "EDIT",
	SHOW = "SHOW"
}

enum TableHeaderType {
	TEXT = "TEXT",
	NUMBER = "NUMBER",
	LINK = "LINK",
	DATE = "DATE",
	ACTIONS = "ACTIONS",
	AMOUNT = "AMOUNT",
	EXPAND = "EXPAND",
	COLOR = "COLOR",
	HIERARCHY = "HIERARCHY"
}

enum MobileVisibilityType {
	DIGITAL_SERVICES = "DIGITAL_SERVICES",
	EASY = "EASY",
	ELIGES = "ELIGES",
	MAIN_RELAX = "MAIN_RELAX",
	MAIN_RELAX_SMART = "MAIN_RELAX_SMART",
	MAIN_RELAX_TOTAL = "MAIN_RELAX_TOTAL",
	MAIN_SHARED_DATA = "MAIN_SHARED_DATA",
	ONLY_MOBILE = "ONLY_MOBILE",
	RELAX_ONLY_MOBILE = "RELAX_ONLY_MOBILE",
	RELAX_TOTAL_ONLY_MOBILE = "RELAX_TOTAL_ONLY_MOBILE",
	SECOND_RESIDENCE = "2ND_RESIDENCE",
	SHARED_DATA = "SHARED_DATA",
	TV = "TV"
}

export {
	CallType,
	CompanyType,
	CustomerAccountBehaviour,
	CustomerNewExtrasProcessStatus,
	DateFormatType,
	FieldType,
	GeneralDialogMode,
	GooglePlaceDataType,
	MobileVisibilityType,
	ProductIconType,
	ProductModificationProcessStatus,
	ProductTransitionProcessStatus,
	ProductType,
	SaleProcessStatus,
	SearchType,
	StatesFormType,
	TableHeaderType
};
