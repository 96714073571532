<template>
	<v-row>
		<v-col>
			<h1>Work In Progress...</h1>
		</v-col>
	</v-row>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
	name: "WorkInProgress"
});
</script>
