import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import { ProductMultipleTransition } from "@/api/models/product-multiple-transition/productMultipleTransition.model";

interface ProductMultipleTransitionProcessState {
	productTransition?: ProductMultipleTransition;
	status?: string;
	shoppingCartId?: string;
}

export const productMultipleTransitionProcessInitialState = () => {
	return {
		productTransition: undefined,
		status: undefined,
		shoppingCartId: undefined
	};
};

const state: ProductMultipleTransitionProcessState = productMultipleTransitionProcessInitialState();

export default {
	state,
	actions,
	mutations,
	getters
};
