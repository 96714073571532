import { UIPAGE } from "@/constants/ui-page.constants";
import { RouteInfo } from "../types";
import { authGuard, permissionGuard, customerProductMultipleTransitionStatusGuard } from "../guards/index";
import { AdditionalProductType } from "@/constants/additional-product-types.constants";

const ViewCustomerProductMultipleTransition = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-multiple-transition" */ "@/views/customer/product-multiple-transition/CustomerProductMultipleTransition.vue"
	);

const CustomerProductMultipleTransitionBundleStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-multiple-transition-bundle" */ "@/views/customer/product-multiple-transition/components/MultipleTransitionBundleStep.vue"
	);

const CustomerProductMultipleTransitionPromotionsStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-multiple-transition-promotions" */ "@/views/customer/product-multiple-transition/components/MultipleTransitionPromotionsStep.vue"
	);
const CustomerProductMultipleTransitionConfigurationStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-multiple-transition-configuration" */ "@/views/customer/product-multiple-transition/components/MultipleTransitionConfigurationStep.vue"
	);
const CustomerProductMultipleTransitionOrderStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-multiple-transition-order" */ "@/views/customer/product-multiple-transition/components/MultipleTransitionOrderStep.vue"
	);

export const CustomerProductOnlyMobilesTransitionRoute: RouteInfo = {
	id: UIPAGE.CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION,
	path: "/customers/:customerId/products/:productId/only-mobiles-transition",
	component: ViewCustomerProductMultipleTransition,
	guards: [authGuard, permissionGuard, customerProductMultipleTransitionStatusGuard],
	props: (route) => ({
		customerId: route.query.customerId,
		productId: route.query.productId,
		additionalProductType: AdditionalProductType.ONLY_MOBILES
	}),
	children: [
		{
			id: UIPAGE.CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_BUNDLE,
			path: "bundle",
			component: CustomerProductMultipleTransitionBundleStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_ID_BUNDLE,
			path: ":transitionId/bundle",
			component: CustomerProductMultipleTransitionBundleStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_PROMOTIONS,
			path: ":transitionId/promotions",
			component: CustomerProductMultipleTransitionPromotionsStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_CONFIGURATION,
			path: ":transitionId/configuration",
			component: CustomerProductMultipleTransitionConfigurationStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_ONLY_MOBILES_TRANSITION_ORDER,
			path: ":transitionId/order",
			component: CustomerProductMultipleTransitionOrderStep,
			title: "customers.products.migrate.display"
		}
	]
};

export const CustomerProductSharedMobilesTransitionRoute: RouteInfo = {
	id: UIPAGE.CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION,
	path: "/customers/:customerId/products/:productId/shared-mobiles-transition",
	component: ViewCustomerProductMultipleTransition,
	guards: [authGuard, permissionGuard, customerProductMultipleTransitionStatusGuard],
	props: (route) => ({
		customerId: route.query.customerId,
		productId: route.query.productId,
		additionalProductType: AdditionalProductType.SHARED_MOBILES
	}),
	children: [
		{
			id: UIPAGE.CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_BUNDLE,
			path: "bundle",
			component: CustomerProductMultipleTransitionBundleStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_ID_BUNDLE,
			path: ":transitionId/bundle",
			component: CustomerProductMultipleTransitionBundleStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_PROMOTIONS,
			path: ":transitionId/promotions",
			component: CustomerProductMultipleTransitionPromotionsStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_CONFIGURATION,
			path: ":transitionId/configuration",
			component: CustomerProductMultipleTransitionConfigurationStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_SHARED_MOBILES_TRANSITION_ORDER,
			path: ":transitionId/order",
			component: CustomerProductMultipleTransitionOrderStep,
			title: "customers.products.migrate.display"
		}
	]
};
