import { Address } from "@/api/models/address/address.model";
import { Apartment } from "@/api/models/address/apartment.model";
import { Coordinates } from "@/api/models/address/coordinates.model";
interface SaleProcessState {
	id: number;
	status: string;
	sale: {
		firstName: string;
		phoneNumber: string;
		coverageSearch: {
			searchTerm: string;
			searchType: string;
		};
		address: {
			id: string;
			address: string;
			salesArea: string;
			status: string;
			lat: string;
			lng: string;
		};
		apartment: Apartment;
		customer: {
			customerType: string;
			type: string;
			number: string;
			country: string;
			companyType: string;
		};
		selectedBundle: string;
	};
	addresses: Address[];
	addressSearchedCoords: Coordinates;
	apartments: Apartment[];
	availableNumbers: string[];
	province: string;
}

export const saleProcessInitialState = () => {
	return {
		id: -1,
		status: "",
		sale: {
			firstName: "",
			phoneNumber: "",
			coverageSearch: {
				searchTerm: "",
				searchType: ""
			},
			address: {
				id: "-1",
				address: "",
				salesArea: "",
				status: "",
				lat: "",
				lng: ""
			} as Address,
			apartment: {
				apartmentId: "-1",
				floor: "",
				door: "",
				stair: "",
				network: ""
			} as Apartment,
			customer: {
				customerType: "",
				type: "",
				number: "",
				country: "",
				companyType: ""
			},
			selectedBundle: ""
		},
		addresses: [],
		addressSearchedCoords: {
			lat: -1,
			lng: -1
		},
		apartments: [],
		availableNumbers: [],
		province: ""
	};
};

const state: SaleProcessState = saleProcessInitialState();
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
	state,
	actions,
	mutations,
	getters
};
