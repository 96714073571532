const getProductTransition = (state) => {
	return state.productTransition;
};

const getProductTransitionShoppingCartId = (state) => {
	return state.shoppingCartId;
};

const getProductTransitionStatus = (state) => {
	return state.status;
};

export default {
	getProductTransition,
	getProductTransitionShoppingCartId,
	getProductTransitionStatus
};
