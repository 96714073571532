import { AuthDto, AuthWorkgroupDto } from "@/models/database.model";
import httpClient from "../config/httpClient";

export abstract class UserService {
	private static readonly USER_URL = "/sys03/user";
	private static readonly ADMIN_USER_URL = "/admin/user";

	private static readonly LOGIN_URL = UserService.USER_URL.concat("/login");
	private static readonly GET_CHANNELS_URL = UserService.USER_URL.concat("/channels");
	private static readonly PUT_CHANNEL_URL = UserService.USER_URL.concat("/channel/");
	private static readonly GET_PERMISSIONS_URL = UserService.USER_URL.concat("/permissions");

	private static readonly CREATE_USERS_URL = UserService.ADMIN_USER_URL.concat("/create");
	private static readonly GET_USERS_URL = UserService.ADMIN_USER_URL.concat("/all");

	public static login(username: string, password: string) {
		return httpClient.post(this.LOGIN_URL, {
			username,
			password
		});
	}

	public static getChannels() {
		return httpClient.get(this.GET_CHANNELS_URL);
	}

	public static getPermissions() {
		return httpClient.get(this.GET_PERMISSIONS_URL);
	}

	public static putUserChannel(channel: string) {
		return httpClient.put(this.PUT_CHANNEL_URL.concat(channel));
	}

	public static createUsers(file: File) {
		const formData = new FormData();
		formData.append("file", file);

		return httpClient.post(this.CREATE_USERS_URL, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	}

	public static getUsers() {
		return httpClient.get(this.GET_USERS_URL);
	}

	public static getWorkgroups() {
		const url = `${this.ADMIN_USER_URL}/workgroup/all`;
		return httpClient.get(url);
	}

	public static getUserById({ id }: { id: string }) {
		const url = `${this.ADMIN_USER_URL}/${id}`;
		return httpClient.get(url);
	}

	public static createUser({ data }: { data: AuthDto }) {
		const url = `${this.ADMIN_USER_URL}`;
		return httpClient.post(url, data);
	}

	public static createUserWorkgroup({ data }: { data: AuthWorkgroupDto }) {
		const url = `${this.ADMIN_USER_URL}/user-workgroup`;
		return httpClient.post(url, data);
	}

	public static patchUser({ id, data }: { id: string; data: AuthDto }) {
		const url = `${this.ADMIN_USER_URL}/${id}`;
		return httpClient.patch(url, data);
	}

	public static patchUserWorkgroup({ id, data }: { id: string; data: AuthWorkgroupDto }) {
		const url = `${this.ADMIN_USER_URL}/user-workgroup/${id}`;
		return httpClient.patch(url, data);
	}
}
