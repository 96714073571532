import { RouteInfo } from "./types";
import { authGuard, permissionGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewApprovals = () => import(/* webpackChunkName: "chunk-approvals" */ "@/views/approval/Approvals.vue");
const ViewApprovalDetail = () => import(/* webpackChunkName: "chunk-approvals" */ "@/views/approval/ApprovalDetail.vue");

export const ApprovalsRoute: RouteInfo = {
	id: UIPAGE.APPROVALS,
	path: "/approvals",
	component: ViewApprovals,
	guards: [authGuard, permissionGuard],
	title: "approvals.display",
	icon: "mdi-sticker-check-outline"
};

export const ApprovalDetailRoute: RouteInfo = {
	id: UIPAGE.APPROVAL_DETAIL,
	path: "/approvals/:id",
	component: ViewApprovalDetail,
	guards: [authGuard, permissionGuard],
	title: "approvals.display",
	icon: "mdi-sticker-check-outline",
	props: (route) => ({
		id: route.query.id
	})
};
