import store from "@/store/index";
import { router } from "@/router/index";
import {
	BUNDLE_DISABLED_STATUS,
	CONFIGURATION_DISABLED_STATUS,
	ORDER_DATA_DISABLED_STATUS,
	PROMOTION_DISABLED_STATUS
} from "@/constants/follow-up-action.constants";

const customerProductMultipleTransitionStatusGuard = (to: any, from: any, next: any) => {
	const currentStatus = (store["state"] as any).productMultipleTransitionProcess.status;
	const params = to.params;
	const ONLY_MOBILES_TRANSITION_PATH = `/customers/${params.customerId}/products/${params.productId}/only-mobiles-transition${
		params.transitionId ? `/${params.transitionId}` : ""
	}`;
	const SHARED_MOBILES_TRANSITION_PATH = `/customers/${params.customerId}/products/${params.productId}/shared-mobiles-transition${
		params.transitionId ? `/${params.transitionId}` : ""
	}`;

	switch (to.path) {
		case `${ONLY_MOBILES_TRANSITION_PATH}/bundle`:
		case `${SHARED_MOBILES_TRANSITION_PATH}/bundle`:
			BUNDLE_DISABLED_STATUS.includes(currentStatus) ? router.go(-1) : next();
			break;
		case `${ONLY_MOBILES_TRANSITION_PATH}/promotions`:
		case `${SHARED_MOBILES_TRANSITION_PATH}/promotions`:
			PROMOTION_DISABLED_STATUS.includes(currentStatus) ? router.go(-1) : next();
			break;
		case `${ONLY_MOBILES_TRANSITION_PATH}/configuration`:
		case `${SHARED_MOBILES_TRANSITION_PATH}/configuration`:
			CONFIGURATION_DISABLED_STATUS.includes(currentStatus) ? router.go(-1) : next();
			break;
		case `${ONLY_MOBILES_TRANSITION_PATH}/order`:
		case `${SHARED_MOBILES_TRANSITION_PATH}/order`:
			ORDER_DATA_DISABLED_STATUS.includes(currentStatus) ? router.go(-1) : next();
			break;
	}
};

export { customerProductMultipleTransitionStatusGuard };
