import { Route } from "vue-router";
import store from "@/store/index";
import { UIPAGE } from "@/constants/ui-page.constants";
import { findRoute } from "..";
import { RouteInfo } from "../types";

const getRootPath = () => {
	const permissions = ((store["state"] as any).user?.permissions as string[]) ?? [];
	const hasDashboardPermission = permissions.find((permission) => permission === UIPAGE.DASBOARD);
	if (hasDashboardPermission) {
		const route = findRoute(UIPAGE.DASBOARD);
		return route?.path;
	}

	// get first permission page
	const firstPagePermission = permissions.find((permission) => permission.startsWith("/UI/PAGE/"));
	if (firstPagePermission) {
		const route = findRoute(firstPagePermission.split(".*")[0]);
		return route?.path;
	}
};

const rootPathGuard = (to: Route, from: Route, next: (redirect?: string) => void) => {
	const path = getRootPath() || "/login";

	next(path);
};

const getRootChildrenPath = ({ children = [], defaultRouteId }: { children: RouteInfo[]; defaultRouteId: string }) => {
	const permissions = ((store["state"] as any).user?.permissions as string[]) ?? [];
	if (permissions.includes(defaultRouteId)) {
		const child = children.find((child) => child.id === defaultRouteId);

		if (child) return child.path;
	}

	const firstChildWithPermission = children.find((child) => {
		const permission = permissions.find((permission) => permission === child.id);
		return permission ? true : false;
	});
	if (firstChildWithPermission) return firstChildWithPermission.path;

	return;
};

export { rootPathGuard, getRootPath, getRootChildrenPath };
