import httpClient from "@/api/config/httpClient";
import { ProductModificationProcess } from "@/api/models/customer";
import { contractToContractAPI } from "../adapters/promotions-and-contracts/contracts.adapter";
import { promotionsToPromotionsAPI } from "../adapters/promotions-and-contracts/promotions.adapter";
import { configurationToConfigurationAPI } from "../adapters/configuration.adapter";

const v1Url = `${process.env.VUE_APP_API_URL}/v1/api`;

export abstract class ProductModificationProcessService {
	private static readonly BASE_URL = "sys03/customer/product";
	private static readonly PRODUCT_MODIFICATION_PROCESS_URL = `/${ProductModificationProcessService.BASE_URL}/modification-process`;
	private static readonly MODIFICATION_PROCESS_URL = "modification-process/shopping-cart";
	private static readonly PROMOTIONS_AND_CONTRACTS_URL = "promotions-and-contracts";
	private static readonly PROMOTIONS_URL = "promotions";
	private static readonly CONTRACTS_URL = "contracts";
	private static readonly CONFIGURATION_URL = "configuration";
	private static readonly SUMMARY_URL = "summary";

	public static create({ productModificationProcess }: { productModificationProcess: ProductModificationProcess }) {
		const { productId, shoppingCartId } = productModificationProcess;

		return httpClient.post(this.PRODUCT_MODIFICATION_PROCESS_URL, {
			productId,
			shoppingCartId
		});
	}

	public static getById(modificationId: string) {
		const url = `${this.PRODUCT_MODIFICATION_PROCESS_URL}/${modificationId}`;

		return httpClient.get(url);
	}

	public static update(modificationId: string, data: any) {
		const url = `${this.PRODUCT_MODIFICATION_PROCESS_URL}/${modificationId}`;
		return httpClient.patch(url, data);
	}

	public static send(modificationId: string) {
		const url = `${this.PRODUCT_MODIFICATION_PROCESS_URL}/${modificationId}/send`;
		return httpClient.put(url);
	}

	public static getModificationProcessPromotionsAndContracts({ productId, shoppingCartId }: { productId: string; shoppingCartId: string }) {
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.MODIFICATION_PROCESS_URL}/${shoppingCartId}/${this.PROMOTIONS_AND_CONTRACTS_URL}`;
		return httpClient.get(url);
	}

	public static putModificationProcessPromotions({
		productId,
		shoppingCartId,
		bundles = []
	}: {
		productId: string;
		shoppingCartId: string;
		bundles: any[];
	}) {
		const formattedBundles = bundles.map((bundle) => ({ ...bundle, promotions: promotionsToPromotionsAPI(bundle.promotions) }));
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.MODIFICATION_PROCESS_URL}/${shoppingCartId}/${this.PROMOTIONS_URL}`;
		return httpClient.put(url, { bundles: formattedBundles });
	}

	public static putModificationProcessContracts({
		productId,
		shoppingCartId,
		bundles = []
	}: {
		productId: string;
		shoppingCartId: string;
		bundles: any[];
	}) {
		const formattedBundles = bundles.map((bundle) => ({ ...bundle, contract: contractToContractAPI(bundle.contract) }));
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.MODIFICATION_PROCESS_URL}/${shoppingCartId}/${this.CONTRACTS_URL}`;
		return httpClient.put(url, { bundles: formattedBundles });
	}

	public static getModificationProcessConfiguration({ productId, shoppingCartId }: { productId: string; shoppingCartId: string }) {
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.MODIFICATION_PROCESS_URL}/${shoppingCartId}/${this.CONFIGURATION_URL}`;
		return httpClient.get(url);
	}

	public static putModificationProcessConfiguration({
		productId,
		shoppingCartId,
		bundles = [],
		parameters = []
	}: {
		productId: string;
		shoppingCartId: string;
		bundles: any[];
		parameters: any;
	}) {
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.MODIFICATION_PROCESS_URL}/${shoppingCartId}/${this.CONFIGURATION_URL}`;
		return httpClient.put(url, configurationToConfigurationAPI({ bundles, parameters }));
	}

	public static getModificationProcessSummary({ productId, shoppingCartId }: { productId: string; shoppingCartId: string }) {
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.MODIFICATION_PROCESS_URL}/${shoppingCartId}/${this.SUMMARY_URL}`;
		return httpClient.get(url);
	}
}
