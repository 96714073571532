import { Address } from "@/api/models/address/address.model";
import { Apartment } from "@/api/models/address/apartment.model";
import { Coordinates } from "@/api/models/address/coordinates.model";

const saveAddresses = (state: any, addresses: Address[]) => {
	state.addresses = addresses;
};

const saveApartments = (state: any, apartments: Apartment[]) => {
	state.apartments = apartments;
};

const resetAddressesAndApartments = (state: any) => {
	state.addresses = [];
	state.apartments = [];
	state.addressSearchedCoords = { lat: -1, lng: -1 };
};

const setAddressSearchedCoords = (state: any, position: Coordinates) => {
	state.addressSearchedCoords = position;
};

const updateAddress = (state: any, address: Address) => {
	if (address) {
		state.sale.address.id = address.id;
		state.sale.address.address = address.address;
		state.sale.address.salesArea = address.salesArea;
		state.sale.address.status = address.status;
		state.sale.address.lat = address.lat;
		state.sale.address.lng = address.lng;
	} else {
		state.sale.address = {
			id: "-1",
			address: "-1",
			salesArea: "-1",
			status: "-1",
			lat: "-1",
			lng: "-1"
		};
	}
};

const updateApartment = (state: any, apartment: any) => {
	if (apartment) {
		state.sale.apartment.apartmentId = apartment.apartmentId ? apartment.apartmentId : apartment.id;
		state.sale.apartment.streetType = apartment.streetType;
		state.sale.apartment.streetName = apartment.streetName;
		state.sale.apartment.streetNumber = apartment.streetNumber;
		state.sale.apartment.floor = apartment.floor;
		state.sale.apartment.stair = apartment.stair;
		state.sale.apartment.door = apartment.door;
		state.sale.apartment.network = apartment.network;
		state.sale.apartment.serviceableStatus = apartment.serviceableStatus;
		state.sale.apartment.salesAreaName = apartment.salesAreaName;
		state.sale.apartment.city = apartment.city;
		state.sale.apartment.province = apartment.province;
	} else {
		state.sale.apartment = {
			apartmentId: "-1",
			streetType: "",
			streetName: "",
			streetNumber: "",
			floor: "",
			stair: "",
			door: "",
			network: "",
			serviceableStatus: "",
			salesAreaName: "",
			city: "",
			province: ""
		};
	}
};

export default {
	saveAddresses,
	saveApartments,
	resetAddressesAndApartments,
	setAddressSearchedCoords,
	updateAddress,
	updateApartment
};
