import { ProductTransition } from "@/api/models/product-transition/productTransition.model";

interface ProductTransitionProcessState {
	productTransition?: ProductTransition;
	status?: string;
	shoppingCartId?: string;
}

export const productTransitionProcessInitialState = () => {
	return {
		productTransition: undefined,
		status: undefined,
		shoppingCartId: undefined
	};
};

const state: ProductTransitionProcessState = productTransitionProcessInitialState();
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
	state,
	actions,
	mutations,
	getters
};
