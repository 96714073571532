import { jwtDecode } from "jwt-decode";

const logout = (state: any) => {
	state.logged = false;
	state.username = "";
	state.currentChannel = undefined;
	state.channels = [];
	state.permissions = [];
	state.role = undefined;
};

const setUsername = (state: any, username: string) => {
	state.username = username;
};

const checkAuth = (state: any) => {
	state.logged = localStorage.getItem("token") ? true : false;
	const token = localStorage.getItem("token");
	if (token) {
		const decoded: any = jwtDecode(token);
		state.role = decoded.role;
		state.username = decoded.username;
		state.currentChannel = decoded.channel;
	}
};

const saveChannels = (state: any, channels: string[]) => {
	state.channels = channels;
};

const savePermissions = (state: any, permissions: string[]) => {
	state.permissions = permissions;
};

const updateChannel = (state: any, channel: string) => {
	state.currentChannel = channel;
};

export default {
	logout,
	setUsername,
	checkAuth,
	saveChannels,
	updateChannel,
	savePermissions
};
