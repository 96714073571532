import { NumberPoolService } from "@/api/services/numberPool.service";

const getAvailableNumbers = ({ commit, state }: any) => {
	return new Promise((resolve, reject) => {
		const province = state.apartments[0]?.province ? state.apartments[0]?.province : state.province;
		NumberPoolService.getAvailableNumbers(province)
			.then((response) => {
				resolve(response.data);
				commit("updateAvailableNumbers", response.data);
			})
			.catch(reject);
	});
};

const reserveNumber = ({ commit, dispatch }: any, number: string) => {
	return new Promise((resolve, reject) => {
		NumberPoolService.reserveNumber(number)
			.then((response) => {
				dispatch("getAvailableNumbers");
				resolve(response.data);
			})
			.catch(reject);
	});
};

export default {
	getAvailableNumbers,
	reserveNumber
};
