import { ProductTransition } from "@/api/models/product-transition/productTransition.model";
import { productTransitionProcessInitialState } from "./productTransitionProcess";

const setProductTransition = (state: any, productTransition: ProductTransition) => {
	state.productTransition = productTransition;
};

const setProductTransitionShoppingCartId = (state: any, shoppingCartId: string) => {
	state.shoppingCartId = shoppingCartId;
};

const setProductTransitionStatus = (state: any, status: string) => {
	state.status = status;
};

const clearProductTransitionProccess = (state: any) => {
	Object.assign(state, productTransitionProcessInitialState());
};

export default {
	setProductTransition,
	setProductTransitionShoppingCartId,
	setProductTransitionStatus,
	clearProductTransitionProccess
};
