import { TransitionGroupMobile } from "@/api/models/product-transition/productTransitionGroup.model";
import { ProductTransition, Transition } from "@/api/models/product-transition/productTransition.model";
import { DeepPartial } from "@/utils/common.util";

function getTransition({
	groupId,
	fiberBandwidth,
	voipQty,
	mobile
}: {
	groupId?: number;
	fiberBandwidth?: string;
	voipQty: number;
	mobile?: TransitionGroupMobile;
}): Partial<Transition> {
	return {
		groupId,
		fiber: fiberBandwidth ? { bandwidth: fiberBandwidth } : undefined,
		voip: {
			qty: voipQty
		},
		mobile
	};
}

export const TransitionMixin = {
	getTransition,
	getProductTransition(productId: string, transition: Transition, shoppingCartId: string): DeepPartial<ProductTransition> {
		return {
			product: {
				id: productId
			},
			transition: transition,
			shoppingCartId: shoppingCartId
		};
	}
};
