export enum SaleProcessStatusType {
	ADDRESS_CREATED = "address-created",
	ADDRESS_SEARCH = "address-search",
	ADDRESS_SELECTED = "address-selected",
	APARTMENT_SELECTED = "apartment-selected",
	APOLLO = "apollo",
	APPROVED = "approved",
	B2B_CONTACT = "b2bcontact",
	BILLING_ACCOUNT_CREATED = "billing-account-created",
	BILLING_ACCOUNT_UPDATED = "billing-account-updated",
	BUNDLE_SELECTED = "bundle-selected",
	COMPLETED = "completed",
	CONTRACT_DATA_SAVED = "contract-data-saved",
	CONTRACT_DATA_VALIDATED = "contract-data-validated",
	CUSTOMER_ACCOUNT_ADDITIONAL_CONTACTS_HANDLED = "customer-account-additional-contacts-handled",
	CUSTOMER_ACCOUNT_CREATED = "customer-account-created",
	CUSTOMER_ACCOUNT_DOCUMENTS_HANDLED = "customer-account-documents-handled",
	CUSTOMER_ACCOUNT_UPDATED = "customer-account-updated",
	CUSTOMER_TYPE_SELECTED = "customer-type-selected",
	CUSTOMER_TYPE_VALIDATED = "customer-type-validated",
	DEBT = "debt",
	DIGITEL_CANCELED = "digitel-canceled",
	DIGITEL_CERTIFICATION = "digitel-certification",
	DIGITEL_CREATED = "digitel-created",
	DIGITEL_CREATED_WITH_ERRORS = "digitel-created-with-errors",
	DIGITEL_ERROR = "digitel-error",
	DIGITEL_EXPIRED = "digitel-expired",
	DIGITEL_FAILED = "digitel-failed",
	DIGITEL_LAUNCHED = "digitel-launched",
	DIGITEL_REJECTED = "digitel-rejected",
	DIGITEL_SMS_SENT = "digitel-msg-sent",
	DIGITEL_DOCUMENT_CONTRACT_VIEWED = "digitel-document-viewed",
	DIGITEL_PIN_REQUESTED = "digitel-pin-requested",
	DIGITEL_DOCUMENTS_SIGNED = "digitel-documents-signed",
	DIGITEL_PIN_VALIDATION_EXPIRED = "digitel-pin-expired",
	DIGITEL_SIGNED = "digitel-signed",
	DIGITEL_SUCCESS = "digitel-success",
	EXTRAS_SELECTED = "extras-selected",
	FEATURES_SELECTED = "features-selected",
	FH_CONTACT = "fhcontact",
	FRESH = "fresh",
	NEARBY = "nearby",
	NO_COVERAGE = "no-coverage",
	NO_SALE = "no-sale",
	PAYMENT_METHOD_CREATED = "payment-method-created",
	PAYMENT_METHOD_UPDATED = "payment-method-updated",
	PENDING_APPROVAL = "pending-approval",
	PRESALE = "presale",
	SHOPPING_CART_CONFIGURED = "shopping-cart-configured",
	SHOPPING_CART_SAVED = "shopping-cart-saved",
	SMS_ANSWERED = "sms-answered",
	SMS_CERTIFICATION = "sms-certification"
}
