import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

interface ProductModificationProcessState {
	productModification?: any;
	status?: string;
	shoppingCartId?: string;
}

export const productModificationProcessInitialState = (): ProductModificationProcessState => {
	return {
		productModification: undefined,
		status: undefined,
		shoppingCartId: undefined
	};
};

const state = productModificationProcessInitialState();

export default {
	state,
	actions,
	mutations,
	getters
};
