import { DropdownDataResDto } from "@/models/storage.model";

const getDropdownData = (state: any) => {
	return ({ key }: { key: string }): DropdownDataResDto[] => {
		if (!key) return [];
		return state[key] ?? [];
	};
};

export default {
	getDropdownData
};
