import { AdditionalProductType } from "@/constants/additional-product-types.constants";
import httpClient from "../config/httpClient";
import { promotionsToPromotionsAPI } from "../adapters/promotions-and-contracts/promotions.adapter";
import { contractToContractAPI } from "../adapters/promotions-and-contracts/contracts.adapter";
import { configurationToConfigurationAPI } from "../adapters/configuration.adapter";
import { bundlesToExtendedBundles } from "../adapters/promotions-and-contracts/extendedBundle.adapter";

export abstract class ProductMultipleTransitionProcessService {
	private static readonly BASE_URL = "sys03/customers/products";
	private static readonly PRODUCT_MULTIPLE_TRANSITION_PROCESS_URL = "multiple-transition-processes";
	private static readonly PRODUCT_MULTIPLE_TRANSITION_PROCESS_COMPLETE_URL = `/${ProductMultipleTransitionProcessService.BASE_URL}/${ProductMultipleTransitionProcessService.PRODUCT_MULTIPLE_TRANSITION_PROCESS_URL}`;
	private static readonly PROMOTIONS_AND_CONTRACTS_URL = "promotions-and-contracts";
	private static readonly PROMOTIONS_URL = "promotions";
	private static readonly CONTRACTS_URL = "contracts";
	private static readonly CONFIGURATION_URL = "configuration";
	private static readonly SEND_URL = "send";
	private static readonly SHOPPING_CARTS_URL = "shopping-carts";
	private static readonly SUMMARY_URL = "summary";

	public static create(multipleTransition: {
		productId: string;
		transition: any;
		shoppingCartId: string;
		additionalProductType: AdditionalProductType;
	}) {
		return httpClient.post(this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_COMPLETE_URL, multipleTransition);
	}

	public static getById(transitionId: string) {
		const url = `${this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_COMPLETE_URL}/${transitionId}`;
		return httpClient.get(url);
	}

	public static getMultipleTransitionProcessSummary({ productId, shoppingCartId }: { productId: string; shoppingCartId: string }) {
		const url = `/${this.BASE_URL}/${productId}/${this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_URL}/${this.SHOPPING_CARTS_URL}/${shoppingCartId}/${this.SUMMARY_URL}`;
		return httpClient.get(url);
	}

	public static getPromotionsAndContracts({ productId, shoppingCartId }: { productId: string; shoppingCartId: string }) {
		const url = `/${this.BASE_URL}/${productId}/${this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_URL}/${this.SHOPPING_CARTS_URL}/${shoppingCartId}/${this.PROMOTIONS_AND_CONTRACTS_URL}`;
		return httpClient.get(url).then((response) => ({ ...response.data, bundles: bundlesToExtendedBundles(response.data.bundles) }));
	}

	public static async putMultipleTransitionPromotions({
		productId,
		shoppingCartId,
		bundles = []
	}: {
		productId: string;
		shoppingCartId: string;
		bundles: any[];
	}) {
		const formattedBundles = bundles.map((bundle) => ({ ...bundle, promotions: promotionsToPromotionsAPI(bundle.promotions) }));
		const url = `/${this.BASE_URL}/${productId}/${this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_URL}/${this.SHOPPING_CARTS_URL}/${shoppingCartId}/${this.PROMOTIONS_URL}`;
		return httpClient
			.put(url, { bundles: formattedBundles })
			.then((response) => ({ ...response.data, bundles: bundlesToExtendedBundles(response.data.bundles) }));
	}

	public static async putMultipleTransitionContracts({
		productId,
		shoppingCartId,
		bundles = []
	}: {
		productId: string;
		shoppingCartId: string;
		bundles: any[];
	}) {
		const formattedBundles = bundles.map((bundle) => ({ ...bundle, contract: contractToContractAPI(bundle.contract) }));
		const url = `/${this.BASE_URL}/${productId}/${this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_URL}/${this.SHOPPING_CARTS_URL}/${shoppingCartId}/${this.CONTRACTS_URL}`;
		return httpClient
			.put(url, { bundles: formattedBundles })
			.then((response) => ({ ...response.data, bundles: bundlesToExtendedBundles(response.data.bundles) }));
	}

	public static update(transitionId: string, data: any) {
		const url = `${this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_COMPLETE_URL}/${transitionId}`;
		return httpClient.patch(url, data);
	}

	public static getMultipleTransitionConfiguration({ productId, shoppingCartId }: { productId: string; shoppingCartId: string }) {
		const url = `/${this.BASE_URL}/${productId}/${this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_URL}/${this.SHOPPING_CARTS_URL}/${shoppingCartId}/${this.CONFIGURATION_URL}`;
		return httpClient.get(url);
	}

	public static putMultipleTransitionConfiguration({
		productId,
		shoppingCartId,
		bundles = [],
		parameters = []
	}: {
		productId: string;
		shoppingCartId: string;
		bundles: any[];
		parameters: any;
	}) {
		const url = `/${this.BASE_URL}/${productId}/${this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_URL}/${this.SHOPPING_CARTS_URL}/${shoppingCartId}/${this.CONFIGURATION_URL}`;
		return httpClient.put(url, configurationToConfigurationAPI({ bundles, parameters }));
	}

	public static send(transitionId: string) {
		const url = `/${this.BASE_URL}/${this.PRODUCT_MULTIPLE_TRANSITION_PROCESS_URL}/${transitionId}/${this.SEND_URL}`;
		return httpClient.put(url);
	}
}
