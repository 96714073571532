import { CustomerProduct } from "@/api/models/customer";
import { customerProductInitialState } from "./customer";

const setCustomerProduct = (state: any, customerProduct: CustomerProduct) => {
	state.customerProduct = customerProduct;
};

const clearCustomerProduct = (state: any) => {
	Object.assign(state, customerProductInitialState());
};

export default {
	setCustomerProduct,
	clearCustomerProduct
};
