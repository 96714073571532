import httpClient from "../config/httpClient";

export abstract class BulkService {
	private static readonly BASE_URL = "/admin";

	public static billingProcess({ file }: { file: File }) {
		const url = `${this.BASE_URL}/billing/bulk/create/charges`;
		const formData = new FormData();
		formData.append("file", file);

		return httpClient.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	}

	public static uploadBillsToFtp({ file }: { file: File }) {
		const url = `${this.BASE_URL}/billing/upload-bills-to-ftp`;
		const formData = new FormData();
		formData.append("file", file);

		return httpClient.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	}

	public static createCustomerNote({ file }: { file: File }) {
		const url = `${this.BASE_URL}/customer/bulk/create/notes`;
		const formData = new FormData();
		formData.append("file", file);

		return httpClient.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	}

	public static createCustomerTask({ file }: { file: File }) {
		const url = `${this.BASE_URL}/customer/bulk/create/tasks`;
		const formData = new FormData();
		formData.append("file", file);

		return httpClient.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	}

	public static updateCustomerBillingState({ file }: { file: File }) {
		const url = `${this.BASE_URL}/billing/update-bill-state`;
		const formData = new FormData();
		formData.append("file", file);

		return httpClient.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	}

	public static sendBillsByEmailB2B({ file }: { file: File }) {
		const url = `${this.BASE_URL}/billing/send-bills-by-email?billType=b2b`;
		const formData = new FormData();
		formData.append("file", file);

		return httpClient.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	}

	public static sendBillsByEmailB2C({ file }: { file: File }) {
		const url = `${this.BASE_URL}/billing/send-bills-by-email?billType=b2c`;
		const formData = new FormData();
		formData.append("file", file);

		return httpClient.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	}
}
