import { ExtendedShoppingCartPromotionsAndContractsBundle } from "@/models/shopping-cart.model";

export const ContractsMixin = {
	getSelectedContract(bundle: any) {
		return { ...bundle.contracts.find((contract) => contract.qty === 1) };
	},
	updateContractSelection(contract: any, oldSelectedContract: any) {
		return {
			name: contract.name,
			qty: contract.name == oldSelectedContract.name ? (oldSelectedContract.qty == 0 ? 1 : 0) : 1
		};
	},
	areValidContractsBundles(bundles: ExtendedShoppingCartPromotionsAndContractsBundle[]) {
		return !bundles.some((bundle) => bundle.contracts.length > 0 && !bundle.selectedContract?.qty);
	}
};
