import { ProductMultipleTransitionProcessService } from "@/api/services/productMultipleTransitionProcess.service";
import { AdditionalProductType } from "@/constants/additional-product-types.constants";

const createProductOnlyMobilesTransition = ({ commit }: any, payload: { productId: string; transition: any; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.create({ ...payload, additionalProductType: AdditionalProductType.ONLY_MOBILES })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const createProductSharedMobilesTransition = ({ commit }: any, payload: { productId: string; transition: any; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.create({ ...payload, additionalProductType: AdditionalProductType.SHARED_MOBILES })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getProductMultipleTransitionById = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.getById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getMultipleTransitionProcessSummary = ({ commit }: any, { productId, shoppingCartId }: { productId: string; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.getMultipleTransitionProcessSummary({ productId, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getMultipleTransitionPromotionsAndContracts = (
	{ commit }: any,
	{ productId, shoppingCartId }: { productId: string; shoppingCartId: string }
) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.getPromotionsAndContracts({ productId, shoppingCartId })
			.then((data) => {
				resolve(data);
			})
			.catch(reject);
	});
};

const updateMultipleTransitionPromotions = ({ commit }: any, payload: { productId: string; shoppingCartId: string; bundles: any[] }) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.putMultipleTransitionPromotions(payload)
			.then((data) => {
				resolve(data);
			})
			.catch(reject);
	});
};

const updateMultipleTransitionContracts = ({ commit }: any, payload: { productId: string; shoppingCartId: string; bundles: any[] }) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.putMultipleTransitionContracts(payload)
			.then((data) => {
				resolve(data);
			})
			.catch(reject);
	});
};

const updateMultipleTransition = ({ commit }: any, payload: any) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.update(payload.id, payload.data)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getMultipleTransitionConfiguration = ({ commit }: any, { productId, shoppingCartId }: { productId: string; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.getMultipleTransitionConfiguration({ productId, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateMultipleTransitionConfiguration = (
	{ commit }: any,
	payload: { productId: string; shoppingCartId: string; bundles: any[]; parameters: any[] }
) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.putMultipleTransitionConfiguration(payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const sendMultipleTransition = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ProductMultipleTransitionProcessService.send(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const saveProductMultipleTransition = ({ commit }: any, transition: any) => {
	commit("setProductMultipleTransition", transition);
};

const saveProductMultipleTransitionShoppingCartId = ({ commit }: any, shoppingCartId: string) => {
	commit("setProductMultipleTransitionShoppingCartId", shoppingCartId);
};

const saveProductMultipleTransitionStatus = ({ commit }: any, status: string) => {
	commit("setProductMultipleTransitionStatus", status);
};

export default {
	createProductOnlyMobilesTransition,
	createProductSharedMobilesTransition,
	getProductMultipleTransitionById,
	getMultipleTransitionProcessSummary,
	getMultipleTransitionPromotionsAndContracts,
	updateMultipleTransitionPromotions,
	updateMultipleTransitionContracts,
	updateMultipleTransition,
	getMultipleTransitionConfiguration,
	updateMultipleTransitionConfiguration,
	sendMultipleTransition,
	saveProductMultipleTransition,
	saveProductMultipleTransitionShoppingCartId,
	saveProductMultipleTransitionStatus
};
