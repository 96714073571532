export const downloadFile = (data: any, fileName: string, fileType) => {
	const downloadUrl = window.URL.createObjectURL(new Blob([data], { type: fileType }));
	const link = document.createElement("a");
	link.href = downloadUrl;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();
};

export const openFile = (data: any, fileType) => {
	const downloadUrl = window.URL.createObjectURL(new Blob([data], { type: fileType }));
	const link = document.createElement("a");
	link.href = downloadUrl;
	link.target = "_blank";
	document.body.appendChild(link);
	link.click();
};
