import { DropdownDataResDto } from "@/models/storage.model";

const clearStorage = (state: any) => {
	Object.keys(state).forEach((key) => {
		state[key] = undefined;
	});
};

const saveCustomerAccountDocumentTypes = (state: any, customerAccountDocumentTypes: string[]) => {
	state.customerAccountDocumentTypes = customerAccountDocumentTypes;
};

const saveDropdownData = (state: any, { key, data }: { key: string; data: DropdownDataResDto }) => {
	state[key] = data;
};

const saveConfiguration = (state: any, { key, data }: { key: string; data: any }) => {
	state[key] = data;
};

export default {
	clearStorage,
	saveDropdownData,
	saveConfiguration,
	saveCustomerAccountDocumentTypes
};
