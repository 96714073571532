import httpClient from "../config/httpClient";
import {
	transitionConfigurationAPIToTransitionConfiguration,
	transitionConfigurationToTransitionConfigurationAPI
} from "@/api/adapters/admin/productTransitionConfiguration.adapter";
import { ProductTransitionConfiguration, ProductTransitionConfigurationAPI } from "@/api/models/admin/productTransitionConfiguration.model";
import {
	characteristicEquivalenceAPIToCharacteristicEquivalence,
	characteristicEquivalenceToCharacteristicEquivalenceAPI
} from "../adapters/admin/characteristicEquivalence.adapter";
import { CharacteristicEquivalence, CharacteristicEquivalenceAPI } from "../models/admin/characteristicEquivalence.model";

export abstract class AdminService {
	private static readonly PRODUCT_TRANSITION_CONFIGURATION_URL = `/admin/product-transition-configurations`;
	private static readonly PRODUCT_CHARACTERISTIC_EQUIVALENCE_URL = `/admin/product-characteristic-equivalences`;

	// PRODUCTS TRANSITION CONFIGURATION

	public static getProductsTransitionConfiguration() {
		return httpClient
			.get(this.PRODUCT_TRANSITION_CONFIGURATION_URL)
			.then((response) =>
				response.data.map((configuration: ProductTransitionConfigurationAPI) => transitionConfigurationAPIToTransitionConfiguration(configuration))
			);
	}

	public static createProductTransition(product: string) {
		return httpClient.post(this.PRODUCT_TRANSITION_CONFIGURATION_URL, {
			product
		});
	}

	public static updateProductTransition(productId: number, product: ProductTransitionConfiguration) {
		return httpClient.patch(
			`${this.PRODUCT_TRANSITION_CONFIGURATION_URL}/${productId}`,
			transitionConfigurationToTransitionConfigurationAPI(product)
		);
	}

	// PROMOTIONS EQUIVALENCES CONFIGURATION

	public static getCharacteristicsEquivalences() {
		return httpClient
			.get(this.PRODUCT_CHARACTERISTIC_EQUIVALENCE_URL)
			.then((response) =>
				response.data.map((equivalence: CharacteristicEquivalenceAPI) => characteristicEquivalenceAPIToCharacteristicEquivalence(equivalence))
			);
	}

	public static createCharacteristicEquivalence(equivalence: CharacteristicEquivalence) {
		return httpClient.post(this.PRODUCT_CHARACTERISTIC_EQUIVALENCE_URL, {
			...equivalence
		});
	}

	public static updateCharacteristicEquivalence(equivalenceId: number, equivalence: CharacteristicEquivalence) {
		return httpClient.patch(
			`${this.PRODUCT_CHARACTERISTIC_EQUIVALENCE_URL}/${equivalenceId}`,
			characteristicEquivalenceToCharacteristicEquivalenceAPI(equivalence)
		);
	}
}
