import { RouteInfo } from "../types";
import { authGuard, permissionGuard, customerProductTransitionStatusGuard } from "../guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewCustomerProductTransition = () =>
	import(/* webpackChunkName: "chunk-customer-product-transition" */ "@/views/customer/product-transition/CustomerProductTransition.vue");

const CustomerProductTransitionBundleStep = () =>
	import(/* webpackChunkName: "chunk-customer-product-transition" */ "@/views/customer/product-transition/components/TransitionBundleStep.vue");
const CustomerProductTransitionPromotionsStep = () =>
	import(/* webpackChunkName: "chunk-customer-product-transition" */ "@/views/customer/product-transition/components/TransitionPromotionsStep.vue");
const CustomerProductTransitionConfigurationStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-transition" */ "@/views/customer/product-transition/components/TransitionConfigurationStep.vue"
	);
const CustomerProductTransitionOrderStep = () =>
	import(/* webpackChunkName: "chunk-customer-product-transition" */ "@/views/customer/product-transition/components/TransitionOrderStep.vue");

export const CustomerProductTransitionRoute: RouteInfo = {
	id: UIPAGE.CUSTOMER_PRODUCT_TRANSITION,
	path: "/customers/:customerId/products/:productId/transition",
	component: ViewCustomerProductTransition,
	guards: [authGuard, permissionGuard, customerProductTransitionStatusGuard],
	props: (route) => ({
		customerId: route.query.customerId,
		productId: route.query.productId
	}),
	title: "customers.products.migrate.display",
	children: [
		{
			id: UIPAGE.CUSTOMER_PRODUCT_TRANSITION_BUNDLE,
			path: "bundle",
			component: CustomerProductTransitionBundleStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_TRANSITION_ID_BUNDLE,
			path: ":transitionId/bundle",
			component: CustomerProductTransitionBundleStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_TRANSITION_PROMOTIONS,
			path: ":transitionId/promotions",
			component: CustomerProductTransitionPromotionsStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_TRANSITION_CONFIGURATION,
			path: ":transitionId/configuration",
			component: CustomerProductTransitionConfigurationStep,
			title: "customers.products.migrate.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_TRANSITION_ORDER,
			path: ":transitionId/order",
			component: CustomerProductTransitionOrderStep,
			title: "customers.products.migrate.display"
		}
	]
};
