import store from "@/store/index";
import { router } from "@/router/index";
import {
	BUNDLE_DISABLED_STATUS,
	CONFIGURATION_DISABLED_STATUS,
	ORDER_DATA_DISABLED_STATUS,
	PROMOTION_DISABLED_STATUS
} from "@/constants/follow-up-action.constants";

const customerNewExtrasStatusGuard = (to: any, from: any, next: any) => {
	const currentStatus = (store["state"] as any).newExtrasProcess.status;
	const params = to.params;
	const CUSTOMER_NEW_EXTRAS_PATH = `/customers/${params.customerId}/new-extras${params.newExtrasId ? `/${params.newExtrasId}` : ""}`;
	switch (to.path) {
		case `${CUSTOMER_NEW_EXTRAS_PATH}/bundle`:
			BUNDLE_DISABLED_STATUS.includes(currentStatus) ? router.go(-1) : next();
			break;
		case `${CUSTOMER_NEW_EXTRAS_PATH}/promotions`:
			PROMOTION_DISABLED_STATUS.includes(currentStatus) ? router.go(-1) : next();
			break;
		case `${CUSTOMER_NEW_EXTRAS_PATH}/configuration`:
			CONFIGURATION_DISABLED_STATUS.includes(currentStatus) ? router.go(-1) : next();
			break;
		case `${CUSTOMER_NEW_EXTRAS_PATH}/order`:
			ORDER_DATA_DISABLED_STATUS.includes(currentStatus) ? router.go(-1) : next();
			break;
	}
};

export { customerNewExtrasStatusGuard };
