import httpClient from "@/api/config/httpClient";
import { NewExtrasProcess } from "@/api/models/customer";

export abstract class NewExtrasProcessService {
	private static readonly NEW_EXTRAS_PROCESS_URL = "/sys03/customer/new-extras-process";

	public static create({ newExtrasProcess }: { newExtrasProcess: NewExtrasProcess }) {
		const { customerId, shoppingCartId } = newExtrasProcess;

		return httpClient.post(this.NEW_EXTRAS_PROCESS_URL, {
			customerId,
			shoppingCartId
		});
	}

	public static getById(newExtrasId: string) {
		const url = `${this.NEW_EXTRAS_PROCESS_URL}/${newExtrasId}`;

		return httpClient.get(url);
	}

	public static update(newExtrasId: string, data: any) {
		const url = `${this.NEW_EXTRAS_PROCESS_URL}/${newExtrasId}`;
		return httpClient.patch(url, data);
	}

	public static send(newExtrasId: string) {
		const url = `${this.NEW_EXTRAS_PROCESS_URL}/${newExtrasId}/send`;
		return httpClient.put(url);
	}
}
