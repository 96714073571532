import { Apartment } from "@/api/models/address/apartment.model";
import { apartmentInitialState } from "./apartment";

const setApartment = (state: any, apartment: Apartment) => {
	state.apartment = apartment;
};

const clearApartment = (state: any) => {
	Object.assign(state, apartmentInitialState());
};

export default {
	setApartment,
	clearApartment
};
