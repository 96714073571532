import { CustomerService } from "@/api/services/customer.service";
import {
	SearchCustomerFilters,
	CustomerFollowUpFilter,
	CustomerProduct,
	TerminateProductOrderData,
	ProductActionType,
	ProductStates
} from "@/api/models/customer";
import { ModificationCheck } from "@/api/models/product-modification";
import { downloadFile } from "@/utils/file.util";
import { MultipleTransitionBundlesData } from "@/api/models/product-multiple-transition/productMultipleTransition.model";
import { ProductTransitionGroup } from "@/api/models/product-transition/productTransitionGroup.model";
import { TransitionCheck } from "@/api/models/product-transition/productTransition.model";

const getCustomerById = ({ commit }: any, { id }: { id: string }) => {
	return new Promise((resolve, reject) => {
		CustomerService.getById({ id })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomersDashboardInfo = ({ commit }: any, filters: SearchCustomerFilters) => {
	return new Promise((resolve, reject) => {
		CustomerService.getDashboardInformation(filters)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerDashboardModificationsById = ({ commit }: any, { customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }) => {
	return new Promise((resolve, reject) => {
		CustomerService.getDashboardModificationsById({ customerId, filters })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerDashboardNewExtrasById = ({ commit }: any, { customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerDashboardNewExtrasById({ customerId, filters })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerDashboardMultipleTransitionsById = (
	{ commit }: any,
	{ customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }
) => {
	return new Promise((resolve, reject) => {
		CustomerService.getDashboardMultipleTransitionsById({ customerId, filters })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerDashboardTransitionsById = ({ commit }: any, { customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }) => {
	return new Promise((resolve, reject) => {
		CustomerService.getDashboardTransitionsById({ customerId, filters })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomersDashboardCsv = ({ commit }: any, filters: any) => {
	return new Promise((resolve, reject) => {
		CustomerService.getDashboardCsv(filters)
			.then((response) => {
				downloadFile(response.data, "dashboard-customers.csv", "text/csv");
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerFinancesById = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerFinancesById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerNotesById = ({ commit }: any, data: any) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerNotesById(data.id, data.filters)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerTasksById = ({ commit }: any, data: any) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerTasksById(data.id, data.filters)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerTaskById = ({ commit }: any, ids: any) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerTaskById(ids.customerId, ids.taskId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerOrdersById = ({ commit }: any, data: any) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerOrdersById(data.id, data.filters)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerOrderById = ({ commit }: any, ids: any) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerOrderById(ids.customerId, ids.orderId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerChargesAndCreditsById = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerChargesAndCreditsById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerProductsById = ({ commit }: any, { id, states }: { id: string; states?: ProductStates[] }) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerProductsById(id, states)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerProductById = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerProductById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

/**
 * Validate method to customer products actions
 */
const getCustomerProductsActionValidateById = ({ commit }: any, data: any) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerProductsActionValidateById(data.id, data.action)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerProductsActionDuplicateValidateById = ({ commit }: any, { id, action }: { id: string; action: ProductActionType }) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerProductsActionDuplicateValidateById(id, action)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

/**
 * Validate method to follow up actions
 */
const getCustomerProductsActionOrderValidateById = ({ commit }: any, data: any) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerProductsActionOrderValidateById(data.id, data.action)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const checkCustomerProductTransitionById = ({ commit }: any, { productId, transition }: { productId: string; transition: TransitionCheck }) => {
	return new Promise<{ shoppingCartId: string }>((resolve, reject) => {
		CustomerService.checkCustomerProductTransitionById(productId, transition)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const checkCustomerProductOnlyMobilesTransitionById = (
	{ commit }: any,
	{ productId, transition }: { productId: string; transition: TransitionCheck }
) => {
	return new Promise<{ shoppingCartId: string }>((resolve, reject) => {
		CustomerService.checkCustomerProductOnlyMobilesTransitionById(productId, transition)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const checkCustomerProductSharedMobilesTransitionById = (
	{ commit }: any,
	{ productId, transition }: { productId: string; transition: TransitionCheck }
) => {
	return new Promise<{ shoppingCartId: string }>((resolve, reject) => {
		CustomerService.checkCustomerProductSharedMobilesTransitionById(productId, transition)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const checkCustomerProductModificationById = (
	{ commit }: any,
	{ productId, modification }: { productId: string; modification: ModificationCheck }
) => {
	return new Promise<{ shoppingCartId: string }>((resolve, reject) => {
		CustomerService.checkCustomerProductModificationById({ productId, modification })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const fetchCustomerProductTransitionGroups = ({ commit }: any, { id, action }: { id: string; action: string }) => {
	return new Promise<ProductTransitionGroup[]>((resolve, reject) => {
		CustomerService.getCustomerProductTransitionGroups(id, action)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const getCustomerProductModificationById = ({ commit }: any, { id, shoppingCartId }: { id: string; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerProductModificationById({ id, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerProductTransitionById = ({ commit }: any, { id, shoppingCartId }: { id: string; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerProductTransitionById({ id, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const terminateCustomerProductById = ({ commit }: any, { id, order }: { id: string; order: TerminateProductOrderData }) => {
	return new Promise((resolve, reject) => {
		CustomerService.terminateCustomerProductById({ id, order })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getCustomerAvailableExtrasValidateById = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerAvailableExtrasValidateById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const fetchAvailableExtras = ({ commit }: any, { id, shoppingCartId }: { id: string; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		CustomerService.getCustomerAvailableExtras({ id, shoppingCartId })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const getCustomerProductOnlyMobilesTransitionBundles = (
	{ commit }: any,
	{ id, shoppingCartId, groupId }: { id: string; shoppingCartId: string; groupId?: number }
) => {
	return new Promise<MultipleTransitionBundlesData>((resolve, reject) => {
		CustomerService.getCustomerProductOnlyMobilesTransitionBundles({ id, shoppingCartId, groupId })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const getCustomerProductSharedMobilesTransitionBundles = (
	{ commit }: any,
	{ id, shoppingCartId, groupId }: { id: string; shoppingCartId: string; groupId?: number }
) => {
	return new Promise<MultipleTransitionBundlesData>((resolve, reject) => {
		CustomerService.getCustomerProductSharedMobilesTransitionBundles({ id, shoppingCartId, groupId })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const saveCustomerProduct = ({ commit }: any, customerProduct: CustomerProduct) => {
	commit("setCustomerProduct", customerProduct);
};

export default {
	checkCustomerProductModificationById,
	checkCustomerProductOnlyMobilesTransitionById,
	checkCustomerProductSharedMobilesTransitionById,
	checkCustomerProductTransitionById,
	fetchAvailableExtras,
	fetchCustomerProductTransitionGroups,
	getCustomerAvailableExtrasValidateById,
	getCustomerById,
	getCustomerChargesAndCreditsById,
	getCustomerDashboardModificationsById,
	getCustomerDashboardMultipleTransitionsById,
	getCustomerDashboardNewExtrasById,
	getCustomerDashboardTransitionsById,
	getCustomerFinancesById,
	getCustomerNotesById,
	getCustomerOrderById,
	getCustomerOrdersById,
	getCustomerProductById,
	getCustomerProductModificationById,
	getCustomerProductOnlyMobilesTransitionBundles,
	getCustomerProductsActionDuplicateValidateById,
	getCustomerProductsActionOrderValidateById,
	getCustomerProductsActionValidateById,
	getCustomerProductsById,
	getCustomerProductSharedMobilesTransitionBundles,
	getCustomerProductTransitionById,
	getCustomersDashboardCsv,
	getCustomersDashboardInfo,
	getCustomerTaskById,
	getCustomerTasksById,
	saveCustomerProduct,
	terminateCustomerProductById
};
