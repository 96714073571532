import { DataService } from "@/api/services/data.service";
import { DropdownDataResDto } from "@/models/storage.model";

const clearStorage = ({ commit }: any) => {
	commit("clearStorage");
};

const getDependencyGroups = ({ commit }: any) => {
	return new Promise((resolve, reject) => {
		DataService.getDependencyGroups()
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getDependencyNonGroups = ({ commit }: any) => {
	return new Promise((resolve, reject) => {
		DataService.getDependencyNonGroups()
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getVoipPrefixes = ({ commit, rootState }: any) => {
	return new Promise((resolve, reject) => {
		const province = rootState.saleProcess.addresses[0]?.province ? rootState.saleProcess.addresses[0].province : rootState.saleProcess.province;
		DataService.getVoipPrefixes()
			.then((response) => {
				const prefixes = response.data[province];
				resolve(prefixes ? prefixes : []);
			})
			.catch(reject);
	});
};

const findDropdownData = ({ commit }: any, { key }: { key: string }) => {
	return new Promise((resolve, reject) => {
		DataService.findDropdownData(key)
			.then((data) => {
				commit("saveDropdownData", { key, data });
				resolve(data);
			})
			.catch(reject);
	});
};

const loadDropdownData = ({ commit, getters }: any, { key }: { key: string }) => {
	return new Promise((resolve, reject) => {
		if (!key) return resolve([]);

		const storeDropdownData = getters.getDropdownData({ key }) as DropdownDataResDto[];
		if (storeDropdownData.length > 0) {
			return resolve(storeDropdownData);
		}

		DataService.findDropdownData(key)
			.then((data) => {
				commit("saveDropdownData", { key, data });
				resolve(data);
			})
			.catch(reject);
	});
};

const loadPermissions = ({ commit, dispatch }: any) => {
	return new Promise((resolve, reject) => {
		dispatch("loadUserPermissions")
			.then(() => resolve(true))
			.catch(() => resolve(false));
	});
};

export default {
	loadPermissions,
	clearStorage,
	findDropdownData,
	loadDropdownData,
	getDependencyGroups,
	getDependencyNonGroups,
	getVoipPrefixes
};
