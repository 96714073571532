import { AxiosResponse } from "axios";
import httpClient from "../config/httpClient";
import { Address } from "../models/address/address.model";
import { Apartment } from "../models/address/apartment.model";
export abstract class AddressService {
	private static readonly ADDRESS_URL = "/sys03/address";

	private static readonly SEARCH_BY_FIELD_URL = AddressService.ADDRESS_URL.concat("/by");
	private static readonly SEARCH_BY_AREA_URL = AddressService.ADDRESS_URL.concat("/by/area");

	public static getApprovalApartmentById(apartmentId: string): Promise<AxiosResponse<Apartment, any>> {
		const url = `${AddressService.ADDRESS_URL}/apartments/${apartmentId}`;
		return httpClient.get(url);
	}

	private static getAddressesByField({
		field,
		value,
		allStatuses
	}: {
		field: "zip" | "id" | "apartmentId";
		value: string;
		allStatuses?: boolean;
	}): any {
		const url = `${AddressService.SEARCH_BY_FIELD_URL}/${field}/${value}`;
		return httpClient.get(url, {
			params: {
				allStatuses
			}
		});
	}

	/**
	 * Gets information of an apartment.
	 * @param apartmentId
	 */
	public static getApartmentById(apartmentId: string): Promise<AxiosResponse<Apartment, any>> {
		return this.getAddressesByField({ field: "apartmentId", value: apartmentId });
	}

	/**
	 * Gets information and apartments of an address.
	 * @param addressId
	 */
	public static getAddressById({ id, allStatuses }: { id: string; allStatuses?: boolean }): Promise<AxiosResponse<Address, any>> {
		return this.getAddressesByField({ field: "id", value: id, allStatuses });
	}

	/**
	 * Gets addresses close to the postal code.
	 * @param postalCode
	 */
	public static getAddressesByPostalCode({ zip, allStatuses }: { zip: string; allStatuses?: boolean }): Promise<AxiosResponse<Address[], any>> {
		return this.getAddressesByField({ field: "zip", value: zip, allStatuses });
	}

	/**
	 * Gets addresses close to the coordinates.
	 * @param lng
	 * @param lat
	 */
	public static getAddressesByCoordinates({
		lng,
		lat,
		allStatuses
	}: {
		lng: string;
		lat: string;
		allStatuses: boolean;
	}): Promise<AxiosResponse<Address[], any>> {
		const url = `${AddressService.SEARCH_BY_AREA_URL}/${lng}/${lat}`;
		return httpClient.get(url, {
			timeout: 25000,
			params: { allStatuses }
		});
	}

	// NEW COVERAGE SEARCH NEW ENDPOINTS
	/**
	 * V2
	 * Gets addresses close to the coordinates.
	 * @param lng
	 * @param lat
	 */
	public static getAddressesByCoordinatesV2({ lng, lat }: { lng: string; lat: string }): Promise<AxiosResponse<Address[], any>> {
		const url = `${AddressService.ADDRESS_URL}/area`;
		return httpClient.get(url, {
			timeout: 25000,
			params: { lng, lat }
		});
	}

	/**
	 * V2
	 * Gets information and apartments of an address.
	 * @param id id of the address
	 */
	public static getAddressesByIdV2({ id }: { id: string }): Promise<AxiosResponse<Address, any>> {
		const url = `${AddressService.ADDRESS_URL}/${id}`;
		return httpClient.get(url, {
			timeout: 25000
		});
	}

	/**
	 * Gets addresses close to the postal code.
	 * @param zip
	 */
	public static getAddressesByZipV2({ zip }: { zip: string }): Promise<AxiosResponse<Address[], any>> {
		const url = `${AddressService.ADDRESS_URL}/zip/${zip}`;
		return httpClient.get(url, {
			timeout: 25000
		});
	}
}
