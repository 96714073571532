import { UserService } from "@/api/services/user.service";

const login = ({ dispatch, commit }: any, payload: any) => {
	return new Promise((resolve, reject) => {
		UserService.login(payload.username, payload.password)
			.then((response) => {
				localStorage.setItem("token", response.data.jwt);
				// important
				dispatch("loadPermissions").then((success: boolean) => {
					if (success) {
						commit("setUsername", payload.username);
						commit("checkAuth");

						resolve(response.status);
					} else {
						localStorage.removeItem("token");
						reject("loadPermissions error");
					}
				});
			})
			.catch(reject);
	});
};

const logout = ({ dispatch, commit }: any) => {
	localStorage.removeItem("token");
	commit("logout");
	dispatch("clearStorage");
	window.sessionStorage.clear();
};

const loadUserChannels = ({ commit, state }: any) => {
	return new Promise((resolve, reject) => {
		UserService.getChannels()
			.then((response) => {
				resolve(response.status);
				commit("saveChannels", response.data);
				if (response.data.length > 0) {
					if (!state.user.currentChannel) {
						commit("updateChannel", response.data[0]);
					}
				}
			})
			.catch(reject);
	});
};

const loadUserPermissions = ({ commit, state }: any) => {
	return new Promise((resolve, reject) => {
		UserService.getPermissions()
			.then((response) => {
				const data = response.data;
				const permissions = data.map((item) => item.resource);
				commit("savePermissions", permissions);
				resolve(response.status);
			})
			.catch(reject);
	});
};

const updateUserChannel = ({ commit }: any, channel: string) => {
	return new Promise((resolve, reject) => {
		UserService.putUserChannel(channel)
			.then((response) => {
				commit("updateChannel", channel);
				localStorage.setItem("token", response.data.jwt);
				resolve(response.status);
			})
			.catch(reject);
	});
};

export default {
	login,
	logout,
	loadUserChannels,
	updateUserChannel,
	loadUserPermissions
};
