import httpClient from "@/api/config/httpClient";
import { contractToContractAPI } from "../adapters/promotions-and-contracts/contracts.adapter";
import { promotionsToPromotionsAPI } from "../adapters/promotions-and-contracts/promotions.adapter";
import { configurationToConfigurationAPI } from "../adapters/configuration.adapter";

const v1Url = `${process.env.VUE_APP_API_URL}/v1/api`;

export abstract class ProductTransitionProcessService {
	private static readonly BASE_URL = "sys03/customer/product";
	private static readonly PRODUCT_TRANSITION_PROCESS_URL = `/${ProductTransitionProcessService.BASE_URL}/transition-process`;
	private static readonly TRANSITION_PROCESS_URL = "transition-process/shopping-cart";
	private static readonly PROMOTIONS_AND_CONTRACTS_URL = "promotions-and-contracts";
	private static readonly PROMOTIONS_URL = "promotions";
	private static readonly CONTRACTS_URL = "contracts";
	private static readonly CONFIGURATION_URL = "configuration";
	private static readonly SUMMARY_URL = "summary";

	public static create(productId: string, transition: any, shoppingCartId: string) {
		return httpClient.post(this.PRODUCT_TRANSITION_PROCESS_URL, {
			productId,
			transition,
			shoppingCartId
		});
	}

	public static getById(transitionId: string) {
		const url = `${this.PRODUCT_TRANSITION_PROCESS_URL}/${transitionId}`;
		return httpClient.get(url);
	}

	public static update(transitionId: string, data: any) {
		const url = `${this.PRODUCT_TRANSITION_PROCESS_URL}/${transitionId}`;
		return httpClient.patch(url, data);
	}

	public static send(transitionId: string) {
		const url = `${this.PRODUCT_TRANSITION_PROCESS_URL}/${transitionId}/send`;
		return httpClient.put(url);
	}

	public static getTransitionProcessPromotionsAndContracts({ productId, shoppingCartId }: { productId: string; shoppingCartId: string }) {
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.TRANSITION_PROCESS_URL}/${shoppingCartId}/${this.PROMOTIONS_AND_CONTRACTS_URL}`;
		return httpClient.get(url);
	}

	public static putTransitionProcessPromotions({
		productId,
		shoppingCartId,
		bundles = []
	}: {
		productId: string;
		shoppingCartId: string;
		bundles: any[];
	}) {
		const formattedBundles = bundles.map((bundle) => ({ ...bundle, promotions: promotionsToPromotionsAPI(bundle.promotions) }));
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.TRANSITION_PROCESS_URL}/${shoppingCartId}/${this.PROMOTIONS_URL}`;
		return httpClient.put(url, { bundles: formattedBundles });
	}

	public static putTransitionProcessContracts({
		productId,
		shoppingCartId,
		bundles = []
	}: {
		productId: string;
		shoppingCartId: string;
		bundles: any[];
	}) {
		const formattedBundles = bundles.map((bundle) => ({ ...bundle, contract: contractToContractAPI(bundle.contract) }));
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.TRANSITION_PROCESS_URL}/${shoppingCartId}/${this.CONTRACTS_URL}`;
		return httpClient.put(url, { bundles: formattedBundles });
	}

	public static getTransitionProcessConfiguration({ productId, shoppingCartId }: { productId: string; shoppingCartId: string }) {
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.TRANSITION_PROCESS_URL}/${shoppingCartId}/${this.CONFIGURATION_URL}`;
		return httpClient.get(url);
	}

	public static putTransitionProcessConfiguration({
		productId,
		shoppingCartId,
		bundles = [],
		parameters = []
	}: {
		productId: string;
		shoppingCartId: string;
		bundles: any[];
		parameters: any;
	}) {
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.TRANSITION_PROCESS_URL}/${shoppingCartId}/${this.CONFIGURATION_URL}`;
		return httpClient.put(url, configurationToConfigurationAPI({ bundles, parameters }));
	}

	public static getTransitionProcessSummary({ productId, shoppingCartId }: { productId: string; shoppingCartId: string }) {
		const url = `${v1Url}/${this.BASE_URL}/${productId}/${this.TRANSITION_PROCESS_URL}/${shoppingCartId}/${this.SUMMARY_URL}`;
		return httpClient.get(url);
	}
}
