import { ShoppingCartPromotions } from "@/models/shopping-cart.model";
import { Characteristic, CharacteristicPrice } from "../../models/promotions.model";

export const promotionsToPromotionsAPI = (promotions: ShoppingCartPromotions) => {
	const formattedCharacteristics = promotions.characteristics.map((characteristic) => characteristicToCharacteristicAPI(characteristic));
	const formattedGroups = promotions.characteristicGroups.map((group) => {
		return group.characteristics.map((characteristic) => characteristicToCharacteristicAPI(characteristic));
	});
	return [...formattedCharacteristics, ...formattedGroups.flat()];
};

export const characteristicToCharacteristicAPI = (characteristic: Characteristic) => {
	const formattedPrices = characteristic.prices
		?.map((price) => characteristicPriceToCharacteristicPriceAPI(price))
		.filter((price) => price.amount || price.bindingDate || price.displayName);
	return {
		name: characteristic.name,
		qty: characteristic.qty,
		prices: formattedPrices && formattedPrices.length > 0 ? formattedPrices : undefined
	};
};

const characteristicPriceToCharacteristicPriceAPI = (price: CharacteristicPrice) => ({
	...price,
	amount: price.amount ? price.amount : undefined,
	bindingDate: price.bindingDate ? new Date(price.bindingDate).toISOString().substring(0, 10) : undefined
});
