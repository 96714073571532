import { PermissionUtil } from "@/utils/permission.util";
import Vue from "vue";

Vue.directive("has-permission", {
	inserted(el, binding) {
		const permission = binding.value;

		if (!PermissionUtil.userHasPermission(permission) && el.parentNode) {
			el.parentNode.removeChild(el);
		}
	}
});
