import {
	COVERAGE_DISABLED_STATUS,
	CUSTOMER_DISABLED_STATUS,
	PRODUCTS_DISABLED_STATUS,
	CONTRACT_DISABLED_STATUS
} from "@/constants/sale-status-by-step.constants";
import store from "@/store/index";
import { router } from "@/router/index";

const saleStatusGuard = (to: any, from: any, next: any) => {
	const currentStatus = (store["state"] as any).saleProcess.status;
	const SALE_PATH = "/sale";

	switch (to.path) {
		case `${SALE_PATH}/identification`:
			next();
			break;
		case `${SALE_PATH}/coverage`:
			if (COVERAGE_DISABLED_STATUS.includes(currentStatus)) {
				router.go(-1);
			} else {
				next();
			}
			break;
		case `${SALE_PATH}/customer`:
			if (CUSTOMER_DISABLED_STATUS.includes(currentStatus)) {
				router.go(-1);
			} else {
				next();
			}
			break;
		case `${SALE_PATH}/bundle`:
		case `${SALE_PATH}/extras`:
		case `${SALE_PATH}/promotions`:
		case `${SALE_PATH}/configuration`:
			if (PRODUCTS_DISABLED_STATUS.includes(currentStatus)) {
				router.go(-1);
			} else {
				next();
			}
			break;
		case `${SALE_PATH}/contract`:
			if (CONTRACT_DISABLED_STATUS.includes(currentStatus)) {
				router.go(-1);
			} else {
				next();
			}
			break;
	}
};

export { saleStatusGuard };
