import { ProductType } from "@/api/constants/enums";
import { ColorStatusType, ColorType } from "./colors.constants";
import { ProductInstanceAction } from "./constants";

export type ProductInstanceActionColor = Record<ProductInstanceAction, ColorStatusType | ColorType>;
export type ProductInstanceActionBorder = Record<ProductInstanceAction, string>;

export const ProductInstanceActionDefaultTextColor: ProductInstanceActionColor = {
	[ProductInstanceAction.ADD]: ColorStatusType.GREEN,
	[ProductInstanceAction.NO_CHANGE]: ColorStatusType.BLACK,
	[ProductInstanceAction.DELETE]: ColorStatusType.RED,
	[ProductInstanceAction.SWAP]: ColorStatusType.RED,
	[ProductInstanceAction.MAKE_PRINCIPAL]: ColorStatusType.ORANGE
};

export const ProductInstanceActionDefaultBackgroundColor: ProductInstanceActionColor = {
	[ProductInstanceAction.ADD]: ColorType.WHITE,
	[ProductInstanceAction.NO_CHANGE]: ColorType.WHITE,
	[ProductInstanceAction.DELETE]: ColorType.WHITE,
	[ProductInstanceAction.SWAP]: ColorType.WHITE,
	[ProductInstanceAction.MAKE_PRINCIPAL]: ColorStatusType.WHITE
};

export const ProductInstanceActionDefaultBorder: ProductInstanceActionBorder = {
	[ProductInstanceAction.ADD]: "none",
	[ProductInstanceAction.NO_CHANGE]: "none",
	[ProductInstanceAction.DELETE]: "none",
	[ProductInstanceAction.SWAP]: "none",
	[ProductInstanceAction.MAKE_PRINCIPAL]: "none"
};

export const ColouredProductInstanceActionBackgroundColor: ProductInstanceActionColor = {
	[ProductInstanceAction.ADD]: ColorType.WHITE,
	[ProductInstanceAction.NO_CHANGE]: ColorType.PURPLE_LIGHT,
	[ProductInstanceAction.DELETE]: ColorStatusType.WHITE,
	[ProductInstanceAction.SWAP]: ColorType.WHITE,
	[ProductInstanceAction.MAKE_PRINCIPAL]: ColorStatusType.WHITE
};

export const ColouredProductInstanceActionBorder: ProductInstanceActionBorder = {
	[ProductInstanceAction.ADD]: `1px solid ${ProductInstanceActionDefaultTextColor[ProductInstanceAction.ADD]}`,
	[ProductInstanceAction.NO_CHANGE]: `1px solid ${ColorStatusType.PURPLE}`,
	[ProductInstanceAction.DELETE]: `1px solid ${ProductInstanceActionDefaultTextColor[ProductInstanceAction.DELETE]}`,
	[ProductInstanceAction.SWAP]: `1px solid ${ProductInstanceActionDefaultTextColor[ProductInstanceAction.SWAP]}`,
	[ProductInstanceAction.MAKE_PRINCIPAL]: `1px solid ${ProductInstanceActionDefaultTextColor[ProductInstanceAction.MAKE_PRINCIPAL]}`
};

export const FiberProductInstanceActionTextColor: ProductInstanceActionColor = {
	[ProductInstanceAction.ADD]: ColorStatusType.GREEN,
	[ProductInstanceAction.NO_CHANGE]: ColorStatusType.WHITE,
	[ProductInstanceAction.DELETE]: ColorStatusType.WHITE,
	[ProductInstanceAction.SWAP]: ColorStatusType.RED,
	[ProductInstanceAction.MAKE_PRINCIPAL]: ColorStatusType.ORANGE
};

export const FiberProductInstanceActionTextColorContrast: ProductInstanceActionColor = {
	[ProductInstanceAction.ADD]: ColorStatusType.GREEN,
	[ProductInstanceAction.NO_CHANGE]: ColorStatusType.BLACK,
	[ProductInstanceAction.DELETE]: ColorStatusType.BLACK,
	[ProductInstanceAction.SWAP]: ColorStatusType.RED,
	[ProductInstanceAction.MAKE_PRINCIPAL]: ColorStatusType.ORANGE
};

export const VoipProductInstanceActionTextColor: ProductInstanceActionColor = {
	[ProductInstanceAction.ADD]: ColorStatusType.GREEN,
	[ProductInstanceAction.NO_CHANGE]: ColorStatusType.BLACK,
	[ProductInstanceAction.DELETE]: ColorStatusType.RED,
	[ProductInstanceAction.SWAP]: ColorStatusType.BLACK,
	[ProductInstanceAction.MAKE_PRINCIPAL]: ColorStatusType.BLACK
};

export const ExtraProductInstanceActionTextColor: ProductInstanceActionColor = {
	[ProductInstanceAction.ADD]: ColorStatusType.BLACK,
	[ProductInstanceAction.NO_CHANGE]: ColorStatusType.BLACK,
	[ProductInstanceAction.DELETE]: ColorStatusType.RED,
	[ProductInstanceAction.SWAP]: ColorStatusType.BLACK,
	[ProductInstanceAction.MAKE_PRINCIPAL]: ColorStatusType.BLACK
};

export const ProductTypeInstanceActionTextColor: Record<ProductType, ProductInstanceActionColor> = {
	[ProductType.FIBRE]: FiberProductInstanceActionTextColor,
	[ProductType.VOIP]: VoipProductInstanceActionTextColor,
	[ProductType.MOBILE]: ProductInstanceActionDefaultTextColor,
	[ProductType.TV]: ExtraProductInstanceActionTextColor,
	[ProductType.VOIP_CLOUDPBX]: ExtraProductInstanceActionTextColor,
	[ProductType.VOIP_SIPTRUNK]: ExtraProductInstanceActionTextColor,
	[ProductType.DIGITAL_SERVICES]: ExtraProductInstanceActionTextColor
};

export const ProductTypeInstanceActionTextColorContrast: Record<ProductType, ProductInstanceActionColor> = {
	[ProductType.FIBRE]: FiberProductInstanceActionTextColorContrast,
	[ProductType.VOIP]: VoipProductInstanceActionTextColor,
	[ProductType.MOBILE]: ProductInstanceActionDefaultTextColor,
	[ProductType.TV]: ExtraProductInstanceActionTextColor,
	[ProductType.VOIP_CLOUDPBX]: ExtraProductInstanceActionTextColor,
	[ProductType.VOIP_SIPTRUNK]: ExtraProductInstanceActionTextColor,
	[ProductType.DIGITAL_SERVICES]: ExtraProductInstanceActionTextColor
};

export const ProductTypeInstanceActionIconColor: Record<ProductType, ProductInstanceActionColor> = {
	[ProductType.FIBRE]: FiberProductInstanceActionTextColor,
	[ProductType.VOIP]: VoipProductInstanceActionTextColor,
	[ProductType.MOBILE]: ProductInstanceActionDefaultTextColor,
	[ProductType.TV]: ExtraProductInstanceActionTextColor,
	[ProductType.VOIP_CLOUDPBX]: ExtraProductInstanceActionTextColor,
	[ProductType.VOIP_SIPTRUNK]: ExtraProductInstanceActionTextColor,
	[ProductType.DIGITAL_SERVICES]: ExtraProductInstanceActionTextColor
};

export const ProductTypeInstanceActionBackgroundColor: Record<ProductType, ProductInstanceActionColor> = {
	[ProductType.FIBRE]: ProductInstanceActionDefaultBackgroundColor,
	[ProductType.VOIP]: ColouredProductInstanceActionBackgroundColor,
	[ProductType.MOBILE]: ColouredProductInstanceActionBackgroundColor,
	[ProductType.TV]: ProductInstanceActionDefaultBackgroundColor,
	[ProductType.VOIP_CLOUDPBX]: ProductInstanceActionDefaultBackgroundColor,
	[ProductType.VOIP_SIPTRUNK]: ProductInstanceActionDefaultBackgroundColor,
	[ProductType.DIGITAL_SERVICES]: ProductInstanceActionDefaultBackgroundColor
};

export const ProductTypeInstanceActionBorder: Record<ProductType, ProductInstanceActionBorder> = {
	[ProductType.FIBRE]: ProductInstanceActionDefaultBorder,
	[ProductType.VOIP]: ColouredProductInstanceActionBorder,
	[ProductType.MOBILE]: ColouredProductInstanceActionBorder,
	[ProductType.TV]: ProductInstanceActionDefaultBorder,
	[ProductType.VOIP_CLOUDPBX]: ProductInstanceActionDefaultBorder,
	[ProductType.VOIP_SIPTRUNK]: ProductInstanceActionDefaultBorder,
	[ProductType.DIGITAL_SERVICES]: ProductInstanceActionDefaultBorder
};
