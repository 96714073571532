import { DataService } from "@/api/services";

const fetchDropdowns = ({ commit }: any) => {
	return new Promise((resolve, reject) => {
		DataService.getDropdowns()
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const createDropdown = ({ commit }: any, { data }: { data: any }) => {
	return new Promise((resolve, reject) => {
		DataService.createDropdown(data)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const patchDropdown = ({ commit }: any, { id, data }: { id: number; data: any }) => {
	return new Promise((resolve, reject) => {
		DataService.patchDropdown(id, data)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const fetchDropdownData = ({ commit }: any, { id }: { id: number }) => {
	return new Promise((resolve, reject) => {
		DataService.getDropdownData(id)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const createDropdownData = ({ commit }: any, { data }: { data: any }) => {
	return new Promise((resolve, reject) => {
		DataService.createDropdownData(data)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const patchDropdownData = ({ commit }: any, { id, data }: { id: number; data: any }) => {
	return new Promise((resolve, reject) => {
		DataService.patchDropdownData(id, data)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

export default { fetchDropdowns, createDropdown, patchDropdown, fetchDropdownData, createDropdownData, patchDropdownData };
