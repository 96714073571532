import { RouteInfo } from "./types";
import { authGuard, permissionGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewTrackingLeads = () => import(/* webpackChunkName: "chunk-tracking-leads" */ "@/views/tracking-leads/TrackingLeads.vue");

export const TrackingLeadsRoute: RouteInfo = {
	id: UIPAGE.TRACKING_LEADS,
	path: "/tracking",
	component: ViewTrackingLeads,
	guards: [authGuard, permissionGuard],
	title: "tracking.display",
	icon: "mdi-chart-line-variant"
};
