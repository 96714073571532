import { RouteInfo } from "../types";
import { authGuard, permissionGuard, customerProductModificationStatusGuard } from "../guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewCustomerProductModification = () =>
	import(/* webpackChunkName: "chunk-customer-product-modification" */ "@/views/customer/product-modification/CustomerProductModification.vue");

const CustomerProductModificationBundleStep = () =>
	import(/* webpackChunkName: "chunk-customer-product-modification" */ "@/views/customer/product-modification/components/ModificationBundleStep.vue");
const CustomerProductModificationPromotionsStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-modification" */ "@/views/customer/product-modification/components/ModificationPromotionsStep.vue"
	);
const CustomerProductModificationConfigurationStep = () =>
	import(
		/* webpackChunkName: "chunk-customer-product-modification" */ "@/views/customer/product-modification/components/ModificationConfigurationStep.vue"
	);
const CustomerProductModificationTerminationOrderStep = () =>
	import(/* webpackChunkName: "chunk-customer-product-modification" */ "@/views/customer/product-modification/components/ModificationOrderStep.vue");

export const CustomerProductModificationRoute: RouteInfo = {
	id: UIPAGE.CUSTOMER_PRODUCT_MODIFICATION,
	path: "/customers/:customerId/products/:productId/modification",
	component: ViewCustomerProductModification,
	guards: [authGuard, permissionGuard, customerProductModificationStatusGuard],
	props: (route) => ({
		customerId: route.query.customerId,
		productId: route.query.productId
	}),
	title: "customers.products.change.display",
	children: [
		{
			id: UIPAGE.CUSTOMER_PRODUCT_MODIFICATION_BUNDLE,
			path: "bundle",
			component: CustomerProductModificationBundleStep,
			title: "customers.products.change.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_MODIFICATION_ID_BUNDLE,
			path: ":modificationId/bundle",
			component: CustomerProductModificationBundleStep,
			title: "customers.products.change.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_MODIFICATION_PROMOTIONS,
			path: ":modificationId/promotions",
			component: CustomerProductModificationPromotionsStep,
			title: "customers.products.change.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_MODIFICATION_CONFIGURATION,
			path: ":modificationId/configuration",
			component: CustomerProductModificationConfigurationStep,
			title: "customers.products.change.display"
		},
		{
			id: UIPAGE.CUSTOMER_PRODUCT_MODIFICATION_ORDER,
			path: ":modificationId/order",
			component: CustomerProductModificationTerminationOrderStep,
			title: "customers.products.change.display"
		}
	]
};
