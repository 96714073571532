import { UserService } from "@/api/services";
import { AuthDto, AuthWorkgroupDto } from "@/models/database.model";

const createUsers = ({ commit }: any, { file }: { file: File }) => {
	return new Promise((resolve, reject) => {
		UserService.createUsers(file)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const getUsers = ({ commit }: any) => {
	return new Promise((resolve, reject) => {
		UserService.getUsers()
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const getWorkgroups = ({ commit }: any) => {
	return new Promise((resolve, reject) => {
		UserService.getWorkgroups()
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const fetchUserById = ({ commit }: any, { id }: { id: string }) => {
	return new Promise((resolve, reject) => {
		UserService.getUserById({ id })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const createUser = ({ commit }: any, { data }: { data: AuthDto }) => {
	return new Promise((resolve, reject) => {
		UserService.createUser({ data })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const createUserWorkgroup = ({ commit }: any, { data }: { data: AuthWorkgroupDto }) => {
	return new Promise((resolve, reject) => {
		UserService.createUserWorkgroup({ data })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const patchUser = ({ commit }: any, { id, data }: { id: string; data: AuthDto }) => {
	return new Promise((resolve, reject) => {
		UserService.patchUser({ id, data })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const patchUserWorkgroup = ({ commit }: any, { id, data }: { id: string; data: AuthWorkgroupDto }) => {
	return new Promise((resolve, reject) => {
		UserService.patchUserWorkgroup({ id, data })
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

export default { createUsers, createUser, patchUser, createUserWorkgroup, patchUserWorkgroup, getUsers, getWorkgroups, fetchUserById };
