import { CustomerProductCharacteristic } from "@/api/models/customer";
import { Characteristic, Promotions } from "@/api/models/promotions.model";
import { ExtendedShoppingCartPromotionsAndContractsBundle } from "@/models/shopping-cart.model";
import { ProductUtil } from "@/utils/product.util";

export const PromotionsMixin = {
	getPromotionDescription(promotion: CustomerProductCharacteristic): string {
		return ProductUtil.getCustomerProductCharacteristicDescription({ characteristic: promotion });
	},
	updateCharacteristics(characteristics: Characteristic[], characteristicToUpdate: Characteristic) {
		return characteristics.map((characteristic) => {
			if (characteristicToUpdate.name === characteristic.name) {
				characteristic.qty = characteristicToUpdate.qty;
				characteristic.prices = characteristicToUpdate.prices;
			}
			return characteristic;
		});
	},
	updatePromotionCharacteristics(characteristic: Characteristic, characteristics: any[], isGroup = false) {
		if (isGroup) characteristics = characteristics.map((group) => this.updateCharacteristics(group.characteristics, characteristic));
		else characteristics = this.updateCharacteristics(characteristics, characteristic);
	},
	updateCharacteristicSelection(characteristic: Characteristic) {
		const qty = characteristic.qty === characteristic.max ? characteristic.qty - 1 : characteristic.qty + 1;
		return { ...characteristic, qty };
	},
	areValidCharacteristics(characteristics: Characteristic[]) {
		return characteristics.every((char) => char.qty >= char.min && char.qty <= char.max);
	},
	areValidPromotions(promotions: Promotions) {
		if (!promotions) return true;

		const validCharacteristicsGroups = promotions.characteristicGroups ? this.areValidCharacteristics(promotions.characteristicGroups) : true;
		const validCharacteristics = promotions.characteristics ? this.areValidCharacteristics(promotions.characteristics) : true;

		return validCharacteristicsGroups && validCharacteristics;
	},
	areValidPromotionsBundles(bundles: ExtendedShoppingCartPromotionsAndContractsBundle[]) {
		return bundles.every((bundle) => this.areValidPromotions(bundle.promotions));
	}
};
