import { Route } from "vue-router";
import { authGuard, authLoginGuard } from "./authGuard";
import { rootPathGuard } from "./rootPathGuard";
import { permissionGuard } from "./permissionGuard";
import { saleStatusGuard } from "./saleStatusGuard";
import { RouteGuard } from "../types";
import { customerProductModificationStatusGuard } from "./customerProductModificationStatusGuard";
import { customerProductTransitionStatusGuard } from "./customerProductTransitionStatusGuard";
import { customerProductMultipleTransitionStatusGuard } from "./customerProductMultipleTransitionStatusGuard";
import { customerNewExtrasStatusGuard } from "./customerNewExtrasStatusGuard";

function evaluateGuards(guards: RouteGuard[], to: Route, from: Route, next: (param?: any) => void) {
	const guardsLeft = guards.slice(0); // clone the array so we don't accidentally modify it
	const nextGuard = guardsLeft.shift();

	if (!nextGuard) return next();

	return nextGuard(to, from, (nextArg) => {
		if (!nextArg && guardsLeft.length) {
			return evaluateGuards(guardsLeft, to, from, next);
		}

		return next(nextArg);
	});
}

function multipleGuards(guards: RouteGuard[]) {
	return (to: Route, from: Route, next: () => void) => {
		return evaluateGuards(guards, to, from, next);
	};
}

export {
	authGuard,
	authLoginGuard,
	saleStatusGuard,
	rootPathGuard,
	permissionGuard,
	multipleGuards,
	customerProductModificationStatusGuard,
	customerProductTransitionStatusGuard,
	customerProductMultipleTransitionStatusGuard,
	customerNewExtrasStatusGuard
};
