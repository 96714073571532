import httpClient from "../config/httpClient";

export abstract class CacheService {
	private static readonly BASE_URL = "/admin/cache";
	private static readonly PRODUCT_CATALOG_URL = CacheService.BASE_URL.concat("/product-catalog");

	public static updateProductCatalog() {
		return httpClient.put(this.PRODUCT_CATALOG_URL, undefined, {
			timeout: 0
		});
	}

	public static deleteProductCatalog() {
		return httpClient.delete(this.PRODUCT_CATALOG_URL, {
			timeout: 0
		});
	}

	public static updateItem(type: "bundle" | "tariff-model", name: string) {
		const url = `${CacheService.BASE_URL}/${type}/${name}`;
		return httpClient.put(url);
	}

	public static deleteItem(type: "bundle" | "tariff-model", name: string) {
		const url = `${CacheService.BASE_URL}/${type}/${name}`;
		return httpClient.delete(url);
	}

	public static deleteItemByKeyPattern(keyPattern: string) {
		const url = `${CacheService.BASE_URL}/key-pattern`;
		return httpClient.put(url, { keyPattern });
	}
}
