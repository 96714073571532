import { RouteInfo } from "./types";
import { authGuard, permissionGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewCoverageSearch = () => import(/* webpackChunkName: "chunk-coverage-search" */ "@/views/coverage-search/CoverageSearch.vue");

export const CoverageSearchRoute: RouteInfo = {
	id: UIPAGE.COVERAGE_SEARCH,
	path: "/coverage-search",
	component: ViewCoverageSearch,
	guards: [authGuard, permissionGuard],
	title: "coverage_search.display",
	icon: "mdi-map-search-outline"
};
