const getProductModification = (state) => {
	return state.productModification;
};

const getProductModificationShoppingCartId = (state) => {
	return state.shoppingCartId;
};

const getProductModificationStatus = (state) => {
	return state.status;
};

export default {
	getProductModification,
	getProductModificationShoppingCartId,
	getProductModificationStatus
};
