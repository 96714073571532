import { ProductTransition } from "@/api/models/product-transition/productTransition.model";
import { productMultipleTransitionProcessInitialState } from "./productMultipleTransitionProcess";

const setProductMultipleTransition = (state: any, productTransition: ProductTransition) => {
	state.productTransition = productTransition;
};

const setProductMultipleTransitionShoppingCartId = (state: any, shoppingCartId: string) => {
	state.shoppingCartId = shoppingCartId;
};

const setProductMultipleTransitionStatus = (state: any, status: string) => {
	state.status = status;
};

const clearProductMultipleTransitionProccess = (state: any) => {
	Object.assign(state, productMultipleTransitionProcessInitialState());
};

export default {
	setProductMultipleTransition,
	setProductMultipleTransitionShoppingCartId,
	setProductMultipleTransitionStatus,
	clearProductMultipleTransitionProccess
};
