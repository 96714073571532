import { ProductType } from "@/api/constants/enums";
import { ConfigurationProductAction } from "@/api/models/configuration.model";
import { ColorStatusType } from "./colors.constants";

export const ProductTypeConfigurationActionDefaultBorder = {
	[ConfigurationProductAction.ADD]: "green-border",
	[ConfigurationProductAction.CHANGE]: "orange-border",
	[ConfigurationProductAction.DELETE]: "red-border",
	[ConfigurationProductAction.NO_CHANGE]: "black-border"
};

export const ProductTypeConfigurationActionBorder: Record<ProductType, any> = {
	[ProductType.FIBRE]: ProductTypeConfigurationActionDefaultBorder,
	[ProductType.VOIP]: ProductTypeConfigurationActionDefaultBorder,
	[ProductType.MOBILE]: ProductTypeConfigurationActionDefaultBorder,
	[ProductType.TV]: ProductTypeConfigurationActionDefaultBorder,
	[ProductType.VOIP_CLOUDPBX]: ProductTypeConfigurationActionDefaultBorder,
	[ProductType.VOIP_SIPTRUNK]: ProductTypeConfigurationActionDefaultBorder,
	[ProductType.DIGITAL_SERVICES]: ProductTypeConfigurationActionDefaultBorder
};

export const ProductTypeConfigurationActionIcon: Record<ProductType, any> = {
	[ProductType.FIBRE]: ColorStatusType.WHITE,
	[ProductType.VOIP]: ColorStatusType.BLACK,
	[ProductType.MOBILE]: ColorStatusType.BLACK,
	[ProductType.TV]: ColorStatusType.BLACK,
	[ProductType.VOIP_CLOUDPBX]: ColorStatusType.BLACK,
	[ProductType.VOIP_SIPTRUNK]: ColorStatusType.BLACK,
	[ProductType.DIGITAL_SERVICES]: ColorStatusType.BLACK
};

export const ProductTypeConfigurationActionText: Record<ProductType, any> = {
	[ProductType.FIBRE]: "white-color",
	[ProductType.VOIP]: "black-color",
	[ProductType.MOBILE]: "black-color",
	[ProductType.TV]: "black-color",
	[ProductType.VOIP_CLOUDPBX]: "black-color",
	[ProductType.VOIP_SIPTRUNK]: "black-color",
	[ProductType.DIGITAL_SERVICES]: "black-color"
};
