import { NewExtrasProcessService } from "@/api/services";
import { NewExtrasProcess } from "@/api/models/customer";

const createNewExtras = ({ commit }: any, newExtrasProcess: NewExtrasProcess) => {
	return new Promise((resolve, reject) => {
		NewExtrasProcessService.create({ newExtrasProcess })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getNewExtrasById = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		NewExtrasProcessService.getById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateNewExtras = ({ commit }: any, payload: any) => {
	return new Promise((resolve, reject) => {
		NewExtrasProcessService.update(payload.id, payload.data)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const sendNewExtras = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		NewExtrasProcessService.send(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const saveNewExtras = ({ commit }: any, newExtras: any) => {
	commit("setNewExtras", newExtras);
};

const saveNewExtrasShoppingCartId = ({ commit }: any, shoppingCartId: string) => {
	commit("setNewExtrasShoppingCartId", shoppingCartId);
};

const saveNewExtrasStatus = ({ commit }: any, status: string) => {
	commit("setNewExtrasStatus", status);
};

export default {
	createNewExtras,
	getNewExtrasById,
	updateNewExtras,
	sendNewExtras,
	saveNewExtras,
	saveNewExtrasShoppingCartId,
	saveNewExtrasStatus
};
