import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

interface NewExtrasProcessState {
	newExtras?: any;
	status?: string;
	shoppingCartId?: string;
}

export const newExtrasProcessInitialState = (): NewExtrasProcessState => {
	return {
		newExtras: undefined,
		status: undefined,
		shoppingCartId: undefined
	};
};

const state = newExtrasProcessInitialState();

export default {
	state,
	actions,
	mutations,
	getters
};
