import { RouteInfo } from "../types";
import { authGuard, permissionGuard } from "../guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewCustomerProductTermination = () =>
	import(/* webpackChunkName: "chunk-customer-product-termination" */ "@/views/customer/product-termination/CustomerProductTermination.vue");

const CustomerProductTerminationOrderStep = () =>
	import(/* webpackChunkName: "chunk-customer-product-termination" */ "@/views/customer/product-termination/components/TerminationOrderStep.vue");

export const CustomerProductTerminationRoute: RouteInfo = {
	id: UIPAGE.CUSTOMER_PRODUCT_TERMINATION,
	path: "/customers/:customerId/products/:productId/termination",
	component: ViewCustomerProductTermination,
	guards: [authGuard, permissionGuard],
	props: (route) => ({
		customerId: route.query.customerId,
		productId: route.query.productId
	}),
	children: [
		{
			id: UIPAGE.CUSTOMER_PRODUCT_TERMINATION,
			path: "",
			component: CustomerProductTerminationOrderStep
		}
	]
};
