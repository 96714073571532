import { ProductType } from "@/api/constants/enums";
import { ColorType } from "@/constants/colors.constants";

export const ProductTypeBackgroundColor: Record<ProductType, "" | ColorType> = {
	[ProductType.FIBRE]: ColorType.PURPLE,
	[ProductType.VOIP]: "",
	[ProductType.MOBILE]: "",
	[ProductType.TV]: "",
	[ProductType.VOIP_CLOUDPBX]: "",
	[ProductType.VOIP_SIPTRUNK]: "",
	[ProductType.DIGITAL_SERVICES]: ""
};

export const ProductTypeTextColor: Record<ProductType, "" | ColorType> = {
	[ProductType.FIBRE]: ColorType.WHITE,
	[ProductType.VOIP]: "",
	[ProductType.MOBILE]: "",
	[ProductType.TV]: "",
	[ProductType.VOIP_CLOUDPBX]: "",
	[ProductType.VOIP_SIPTRUNK]: "",
	[ProductType.DIGITAL_SERVICES]: ""
};

export const ProductTypeTextColorContrast: Record<ProductType, "" | ColorType> = {
	[ProductType.FIBRE]: "",
	[ProductType.VOIP]: "",
	[ProductType.MOBILE]: "",
	[ProductType.TV]: "",
	[ProductType.VOIP_CLOUDPBX]: "",
	[ProductType.VOIP_SIPTRUNK]: "",
	[ProductType.DIGITAL_SERVICES]: ""
};

export const ProductTypeIconColor: Record<ProductType, "" | ColorType> = {
	[ProductType.FIBRE]: ColorType.WHITE,
	[ProductType.VOIP]: "",
	[ProductType.MOBILE]: "",
	[ProductType.TV]: "",
	[ProductType.VOIP_CLOUDPBX]: "",
	[ProductType.VOIP_SIPTRUNK]: "",
	[ProductType.DIGITAL_SERVICES]: ""
};

export const ProductTypeBorder: Record<ProductType, string> = {
	[ProductType.FIBRE]: "none",
	[ProductType.VOIP]: "none",
	[ProductType.MOBILE]: "none",
	[ProductType.TV]: "none",
	[ProductType.VOIP_CLOUDPBX]: "none",
	[ProductType.VOIP_SIPTRUNK]: "none",
	[ProductType.DIGITAL_SERVICES]: "none"
};
