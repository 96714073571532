import { CharacteristicEquivalence, CharacteristicEquivalenceAPI } from "@/api/models/admin/characteristicEquivalence.model";

export const characteristicEquivalenceAPIToCharacteristicEquivalence = (equivalence: CharacteristicEquivalenceAPI): CharacteristicEquivalence => ({
	id: equivalence.id,
	active: equivalence.active,
	featureGroup: equivalence.featureGroup,
	fromCharacteristic: equivalence.fromCharacteristic,
	fromProduct: equivalence.fromProduct,
	toCharacteristic: equivalence.toCharacteristic,
	toProduct: equivalence.toProduct,
	createdBy: equivalence.created.by,
	createdAt: equivalence.created.at,
	updatedBy: equivalence.updated.by,
	updatedAt: equivalence.updated.at
});

export const characteristicEquivalenceToCharacteristicEquivalenceAPI = (
	equivalence: CharacteristicEquivalence
): Partial<CharacteristicEquivalenceAPI> => ({
	active: equivalence.active,
	featureGroup: equivalence.featureGroup ?? "",
	fromCharacteristic: equivalence.fromCharacteristic,
	fromProduct: equivalence.fromProduct,
	toCharacteristic: equivalence.toCharacteristic,
	toProduct: equivalence.toProduct
});
