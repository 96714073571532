const getNewExtras = (state) => {
	return state.newExtras;
};

const getNewExtrasShoppingCartId = (state) => {
	return state.shoppingCartId;
};

const getNewExtrasStatus = (state) => {
	return state.status;
};

export default {
	getNewExtras,
	getNewExtrasShoppingCartId,
	getNewExtrasStatus
};
