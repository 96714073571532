import { CacheService } from "@/api/services";

const updateCacheProductCatalog = ({ commit }: any) => {
	return new Promise((resolve, reject) => {
		CacheService.updateProductCatalog()
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const deleteCacheProductCatalog = ({ commit }: any) => {
	return new Promise((resolve, reject) => {
		CacheService.deleteProductCatalog()
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const updateCacheItem = ({ commit }: any, { type, key }: { type: "bundle" | "tariff-model"; key: string }) => {
	return new Promise((resolve, reject) => {
		CacheService.updateItem(type, key)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const deleteCacheItem = ({ commit }: any, { type, key }: { type: "bundle" | "tariff-model"; key: string }) => {
	return new Promise((resolve, reject) => {
		CacheService.deleteItem(type, key)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

const deleteCacheItemByKeyPattern = ({ commit }: any, { keyPattern }: { keyPattern: string }) => {
	return new Promise((resolve, reject) => {
		CacheService.deleteItemByKeyPattern(keyPattern)
			.then((response) => resolve(response.data))
			.catch(reject);
	});
};

export default { updateCacheProductCatalog, deleteCacheProductCatalog, updateCacheItem, deleteCacheItem, deleteCacheItemByKeyPattern };
