import { ProductType } from "@/api/constants/enums";

export const ConfigurationMixin = {
	getBundleTitle(bundle: any, bundleIndex: number) {
		return `${bundleIndex + 1} - ${bundle.displayName}`;
	},
	/**
	 * Validate that the mandatory fields, the correct format of the data
	 * and whether VOIP products without portability have a new number reserved
	 */
	areValidProducts(bundles: any[]) {
		for (const bundle of bundles) {
			const { products = [] } = bundle;

			const areAllProductsValid = products.every((product) => product.valid);
			if (!areAllProductsValid) return false;

			const areVoipProductsValid = products
				.filter((product) => {
					const { type, serviceSpecification = [] } = product;
					if (type !== ProductType.VOIP) return false;

					const hasPhoneNumberField = serviceSpecification.find((field) => field.name === "PHONE_NUMBER" && !field.hidden);
					return hasPhoneNumberField ? true : false;
				})
				.every((product) => product.reservedNumber);
			if (!areVoipProductsValid) return false;

			const productsCharacteristicGroups = products
				.map((product) => {
					const { characteristicGroups = [] } = product;

					return characteristicGroups;
				})
				.flat();
			const areProductsCharacteristicGroupsValid = productsCharacteristicGroups.every((group) => group.qty >= group.min && group.qty <= group.max);
			if (!areProductsCharacteristicGroupsValid) return false;
		}

		return true;
	},

	areVOIPProduct(bundles: any[]) {
		let areVOIPProduct = false;
		bundles.forEach((bundle) => {
			if (!areVOIPProduct) {
				areVOIPProduct = bundle.products.some((product) => product.type === ProductType.VOIP);
			}
		});

		return areVOIPProduct;
	}
};
