import { Apartment } from "../address/apartment.model";
import { ProductType } from "@/api/constants/enums";
import { ColorStatusType } from "@/constants/colors.constants";
import { ChipStyle } from "../common.model";
import { UIACTION } from "@/constants/constants";

export interface CustomerProduct {
	id: string;
	name: string;
	instanceName?: string;
	displayName: string;
	displayDescription?: string;
	bundle: boolean;
	enabled: boolean;
	isMainBundle: boolean;
	actions: ProductActionType[];
	state: ProductStates;
	activatedAt: string;
	terminatedAt?: string;
	contract?: CustomerProductCharacteristic;
	characteristics?: CustomerProductCharacteristic[];
	products?: CustomerProduct[];
	type?: ProductType;
	serviceCharacteristicInstanceValue?: string;
	serviceCharacteristicName?: string;
	serviceCharacteristicValue?: string | Apartment;
}

export interface CustomerProductCharacteristic {
	id: string;
	name: string;
	displayName?: string;
	displayDescription?: string;
	bindingMonths?: number;
	bindingDays?: number;
	activatedAt: string;
	terminatedAt?: string;
	completedPercentage?: number;
	displayAmount?: number;
	includedAmount?: number;
	excludedAmount?: number;
}

export enum ProductActionType {
	MODIFICATION = "CHANGE",
	TRANSITION = "MIGRATE",
	TRANSITION_ONLY_MOBILES = "MIGRATE_ONLY_MOBILES",
	TRANSITION_SHARED_MOBILES = "MIGRATE_SHARED_MOBILES",
	TERMINATION = "TERMINATE",
	NEW_EXTRAS = "NEW_EXTRAS"
}

export const ProductActionTypePermissions: Record<ProductActionType, UIACTION> = {
	[ProductActionType.MODIFICATION]: UIACTION.CUSTOMER_PRODUCT_MODIFICATION_ACTION,
	[ProductActionType.TRANSITION]: UIACTION.CUSTOMER_PRODUCT_TRANSITION_ACTION,
	[ProductActionType.TRANSITION_ONLY_MOBILES]: UIACTION.CUSTOMER_PRODUCT_TRANSITION_ONLY_MOBILES_ACTION,
	[ProductActionType.TRANSITION_SHARED_MOBILES]: UIACTION.CUSTOMER_PRODUCT_TRANSITION_SHARED_MOBILES_ACTION,
	[ProductActionType.TERMINATION]: UIACTION.CUSTOMER_PRODUCT_TERMINATE_ACTION,
	[ProductActionType.NEW_EXTRAS]: "" as UIACTION
};

export enum ProductStates {
	PENDING_ACTIVATE = "PENDING_ACTIVATE",
	ACTIVE = "ACTIVE",
	TERMINATED = "TERMINATED"
}

export const ProductStateConfig: Record<ProductStates, ChipStyle> = {
	[ProductStates.PENDING_ACTIVATE]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.PURPLE
	},
	[ProductStates.ACTIVE]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.PURPLE
	},
	[ProductStates.TERMINATED]: {
		color: ColorStatusType.BLACK,
		background: ColorStatusType.WHITE
	}
};
