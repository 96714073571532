import { RouteInfo } from "../types";
import { authGuard, customerNewExtrasStatusGuard, permissionGuard } from "../guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewCustomerNewExtras = () => import(/* webpackChunkName: "chunk-customer-new-extras" */ "@/views/customer/new-extras/CustomerNewExtras.vue");

const CustomerNewExtrasBundleStep = () =>
	import(/* webpackChunkName: "chunk-customer-new-extras" */ "@/views/customer/new-extras/components/NewExtrasBundleStep.vue");
const CustomerNewExtrasPromotionsStep = () =>
	import(/* webpackChunkName: "chunk-customer-new-extras" */ "@/views/customer/new-extras/components/NewExtrasPromotionsStep.vue");
const CustomerNewExtrasConfigurationStep = () =>
	import(/* webpackChunkName: "chunk-customer-new-extras" */ "@/views/customer/new-extras/components/NewExtrasConfigurationStep.vue");
const CustomerNewExtrasOrderStep = () =>
	import(/* webpackChunkName: "chunk-customer-new-extras" */ "@/views/customer/new-extras/components/NewExtrasOrderStep.vue");

export const CustomerNewExtrasRoute: RouteInfo = {
	id: UIPAGE.CUSTOMER_NEW_EXTRAS,
	path: "/customers/:customerId/new-extras",
	component: ViewCustomerNewExtras,
	guards: [authGuard, permissionGuard, customerNewExtrasStatusGuard],
	props: (route) => ({
		customerId: route.query.customerId
	}),
	title: "customers.new_extras.display",
	children: [
		{
			id: UIPAGE.CUSTOMER_NEW_EXTRAS_BUNDLE,
			path: "bundle",
			component: CustomerNewExtrasBundleStep,
			title: "customers.new_extras.display"
		},
		{
			id: UIPAGE.CUSTOMER_NEW_EXTRAS_ID_BUNDLE,
			path: ":newExtrasId/bundle",
			component: CustomerNewExtrasBundleStep,
			title: "customers.new_extras.display"
		},
		{
			id: UIPAGE.CUSTOMER_NEW_EXTRAS_PROMOTIONS,
			path: ":newExtrasId/promotions",
			component: CustomerNewExtrasPromotionsStep,
			title: "customers.new_extras.display"
		},
		{
			id: UIPAGE.CUSTOMER_NEW_EXTRAS_CONFIGURATION,
			path: ":newExtrasId/configuration",
			component: CustomerNewExtrasConfigurationStep,
			title: "customers.new_extras.display"
		},
		{
			id: UIPAGE.CUSTOMER_NEW_EXTRAS_ORDER,
			path: ":newExtrasId/order",
			component: CustomerNewExtrasOrderStep,
			title: "customers.new_extras.display"
		}
	]
};
