import store from "@/store/index";
import { router } from "@/router/index";
import {
	BUNDLE_DISABLED_STATUS,
	CONFIGURATION_DISABLED_STATUS,
	ORDER_DATA_DISABLED_STATUS,
	PROMOTION_DISABLED_STATUS
} from "@/constants/follow-up-action.constants";

const customerProductTransitionStatusGuard = (to: any, from: any, next: any) => {
	const currentStatus = (store["state"] as any).productTransitionProcess.status;
	const params = to.params;
	const PRODUCT_TRANSITION_PATH = `/customers/${params.customerId}/products/${params.productId}/transition${
		params.transitionId ? `/${params.transitionId}` : ""
	}`;
	switch (to.path) {
		case `${PRODUCT_TRANSITION_PATH}/bundle`:
			if (BUNDLE_DISABLED_STATUS.includes(currentStatus)) {
				router.go(-1);
			} else {
				next();
			}
			break;
		case `${PRODUCT_TRANSITION_PATH}/promotions`:
			if (PROMOTION_DISABLED_STATUS.includes(currentStatus)) {
				router.go(-1);
			} else {
				next();
			}
			break;
		case `${PRODUCT_TRANSITION_PATH}/configuration`:
			if (CONFIGURATION_DISABLED_STATUS.includes(currentStatus)) {
				router.go(-1);
			} else {
				next();
			}
			break;
		case `${PRODUCT_TRANSITION_PATH}/order`:
			if (ORDER_DATA_DISABLED_STATUS.includes(currentStatus)) {
				router.go(-1);
			} else {
				next();
			}
			break;
	}
};

export { customerProductTransitionStatusGuard };
