export interface CustomerChargeAndCredit {
	id: string;
	name: string;
	displayName?: string;
	type: ChargeType;
	displayAmount: number;
	includedAmount: number;
	excludedAmount: number;
	activatedAt: string;
	terminatedAt?: string;
}

enum ChargeType {
	ONCE_ONLY_CHARGE = "ONCE_ONLY_CHARGE",
	RECURRING_CHARGE = "RECURRING_CHARGE"
}
