import { saleProcessInitialState } from "../saleProcess";
import { CompanyType } from "@/api/constants/enums";
import { CustomerType } from "@/api/models/customer";

const saleProcessCreated = (state: any, identification: any) => {
	state.sale.firstName = identification.firstName;
	state.sale.phoneNumber = identification.phoneNumber;
};

const updateId = (state: any, id: string) => {
	state.id = id;
};

const updateStatus = (state: any, status: string) => {
	state.status = status;
};

const updateFirstName = (state: any, firstName: string) => {
	state.sale.firstName = firstName;
};

const updatePhoneNumber = (state: any, phoneNumber: string) => {
	state.sale.phoneNumber = phoneNumber;
};

const updateCoverageSearchTerm = (state: any, searchTerm) => {
	state.sale.coverageSearch.searchTerm = searchTerm;
};

const updateCoverageSearchType = (state: any, searchType) => {
	state.sale.coverageSearch.searchType = searchType;
};

const updateAddressAndApartment = (state: any, { addressId, apartmentId }) => {
	state.sale.address.id = addressId;
	state.sale.apartment.apartmentId = apartmentId;
};

const updateSelectedBundle = (state: any, bundle: string) => {
	state.sale.selectedBundle = bundle;
};

const updateCustomer = (state: any, saleProcess: any) => {
	state.sale.customer.customerType = saleProcess.customer.customerType;
	if (saleProcess.customer.customerType === CustomerType.BUSINESS && saleProcess?.businessInfo.companyType !== CompanyType.INDIVIDUAL) {
		state.sale.customer.type = saleProcess.businessInfo.identification?.type;
		state.sale.customer.number = saleProcess.businessInfo.identification?.number;
		state.sale.customer.country = saleProcess.businessInfo.identification?.country;
		state.sale.customer.companyType = saleProcess.businessInfo.companyType;
	} else {
		state.sale.customer.type = saleProcess.customer.identification?.type;
		state.sale.customer.number = saleProcess.customer.identification?.number;
		state.sale.customer.country = saleProcess.customer.identification?.country;
		state.sale.customer.companyType = saleProcess.businessInfo ? saleProcess.businessInfo.companyType : "";
	}
};

const updateProvince = (state: any, province: string) => {
	state.province = province;
};

const clearSaleProcessState = (state: any) => {
	Object.assign(state, saleProcessInitialState());
};

export default {
	saleProcessCreated,
	updateId,
	updateStatus,
	updateFirstName,
	updatePhoneNumber,
	updateCoverageSearchTerm,
	updateCoverageSearchType,
	updateAddressAndApartment,
	updateSelectedBundle,
	updateCustomer,
	updateProvince,
	clearSaleProcessState
};
