import { SaleProcessStatusType } from "@/constants/sale-process.constants";

const WEB_STATUSES = [
	SaleProcessStatusType.APOLLO,
	SaleProcessStatusType.B2B_CONTACT,
	SaleProcessStatusType.FH_CONTACT,
	SaleProcessStatusType.NEARBY
];

const DIGITEL_STATUSES = [
	SaleProcessStatusType.DIGITEL_CANCELED,
	SaleProcessStatusType.DIGITEL_CERTIFICATION,
	SaleProcessStatusType.DIGITEL_CREATED,
	SaleProcessStatusType.DIGITEL_CREATED_WITH_ERRORS,
	SaleProcessStatusType.DIGITEL_ERROR,
	SaleProcessStatusType.DIGITEL_EXPIRED,
	SaleProcessStatusType.DIGITEL_FAILED,
	SaleProcessStatusType.DIGITEL_LAUNCHED,
	SaleProcessStatusType.DIGITEL_REJECTED,
	SaleProcessStatusType.DIGITEL_SMS_SENT,
	SaleProcessStatusType.DIGITEL_DOCUMENT_CONTRACT_VIEWED,
	SaleProcessStatusType.DIGITEL_PIN_REQUESTED,
	SaleProcessStatusType.DIGITEL_DOCUMENTS_SIGNED,
	SaleProcessStatusType.DIGITEL_PIN_VALIDATION_EXPIRED,
	SaleProcessStatusType.DIGITEL_SIGNED,
	SaleProcessStatusType.DIGITEL_SUCCESS
];

const COVERAGE_DISABLED_STATUS = [
	SaleProcessStatusType.COMPLETED,
	SaleProcessStatusType.DEBT,
	SaleProcessStatusType.NO_COVERAGE,
	SaleProcessStatusType.NO_SALE,
	SaleProcessStatusType.PRESALE,
	SaleProcessStatusType.DIGITEL_SUCCESS,
	SaleProcessStatusType.PENDING_APPROVAL,
	SaleProcessStatusType.APPROVED
];

const CUSTOMER_DISABLED_STATUS = [
	...WEB_STATUSES,
	...COVERAGE_DISABLED_STATUS,
	SaleProcessStatusType.FRESH,
	SaleProcessStatusType.ADDRESS_SEARCH,
	SaleProcessStatusType.ADDRESS_SELECTED
];

const PRODUCTS_DISABLED_STATUS = [
	...CUSTOMER_DISABLED_STATUS,
	SaleProcessStatusType.APARTMENT_SELECTED,
	SaleProcessStatusType.CUSTOMER_TYPE_SELECTED
];

const CONTRACT_DISABLED_STATUS = [
	...PRODUCTS_DISABLED_STATUS,
	SaleProcessStatusType.CUSTOMER_TYPE_VALIDATED,
	SaleProcessStatusType.BUNDLE_SELECTED,
	SaleProcessStatusType.EXTRAS_SELECTED,
	SaleProcessStatusType.FEATURES_SELECTED,
	SaleProcessStatusType.SHOPPING_CART_SAVED
];

export { COVERAGE_DISABLED_STATUS, CUSTOMER_DISABLED_STATUS, PRODUCTS_DISABLED_STATUS, CONTRACT_DISABLED_STATUS, DIGITEL_STATUSES };
