import { RouteInfo } from "./types";
import { authGuard, permissionGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewDashboard = () => import(/* webpackChunkName: "chunk-general" */ "@/views/dashboard/Dashboard.vue");

export const DashboardRoute: RouteInfo = {
	id: UIPAGE.DASBOARD,
	path: "/dashboard",
	component: ViewDashboard,
	guards: [authGuard, permissionGuard],
	title: "dashboard.display",
	icon: "mdi-home-outline"
};
