import {
	ModificationCheck,
	ProductModificationBundle,
	ProductModificationInstance,
	ProductModificationProduct,
	ProductModificationProductGroup
} from "@/api/models/product-modification";

export const productModificationInstanceToProductModificationInstanceAPI = (instances: ProductModificationInstance[]) => {
	return instances.map((instance) => {
		const { id, action, referenceProductId, referenceProductName } = instance;

		return {
			id,
			action,
			referenceProductId,
			referenceProductName
		};
	});
};

export const productModificationProductToProductModificationProductAPI = (product: ProductModificationProduct) => {
	const { name, qty, instances = [] } = product;

	return {
		name,
		qty,
		instances: productModificationInstanceToProductModificationInstanceAPI(instances)
	};
};

export const productModificationProductGroupToProductModificationProductGroupAPI = (group: ProductModificationProductGroup) => {
	const { name, qty, products = [] } = group;

	return { name, qty, products: products.map(productModificationProductToProductModificationProductAPI) };
};

export const productModificationBundleToProductModificationBundleAPI = (bundle: ProductModificationBundle) => {
	const { id, name, products = [], productGroups = [] } = bundle;
	return {
		id,
		name,
		products: products.map(productModificationProductToProductModificationProductAPI),
		productGroups: productGroups.map(productModificationProductGroupToProductModificationProductGroupAPI)
	};
};

export const modificationCheckToModificationCheckAPI = (modification: ModificationCheck) => {
	return {
		shoppingCartId: modification.shoppingCartId,
		bundles: modification.bundles.map(productModificationBundleToProductModificationBundleAPI)
	};
};
