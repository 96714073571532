import { productModificationProcessInitialState } from "./productModificationProcess";

const setProductModification = (state: any, productModification: any) => {
	state.productModification = productModification;
};

const setProductModificationShoppingCartId = (state: any, shoppingCartId: string) => {
	state.shoppingCartId = shoppingCartId;
};

const setProductModificationStatus = (state: any, status: string) => {
	state.status = status;
};

const clearProductModificationProcessState = (state: any) => {
	Object.assign(state, productModificationProcessInitialState());
};

export default {
	setProductModification,
	setProductModificationShoppingCartId,
	setProductModificationStatus,
	clearProductModificationProcessState
};
