interface CustomerProductState {
	customerProduct: CustomerProduct;
}

export const customerProductInitialState = () => {
	return {
		customerProduct: {} as CustomerProduct
	};
};

const state: CustomerProductState = customerProductInitialState();
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import { CustomerProduct } from "@/api/models/customer";

export default {
	state,
	actions,
	mutations,
	getters
};
