import { ShoppingCart } from "@/api/models/shoppingCart.model";
import { shoppingCartInitialState } from "./shoppingCart";

const updateShoppingCartLoading = (state: any, active: boolean) => {
	state.shoppingCartLoading = active;
};

const updateShoppingCartId = (state: any, id: string) => {
	state.shoppingCartId = id;
};

const updateSummary = (state: any, summary: ShoppingCart) => {
	state.summary.bundles = summary ? summary.bundles : [];
	state.summary.prices = summary ? summary.prices : [];
	state.summary.contract = summary ? summary.contract : [];
};

const clearShoppingCartState = (state: any) => {
	Object.assign(state, shoppingCartInitialState());
};

const activateShoppingCartLoading = (state: any) => {
	updateShoppingCartLoading(state, true);
};

const desactivateShoppingCartLoading = (state: any) => {
	updateShoppingCartLoading(state, false);
};

export default {
	updateShoppingCartLoading,
	updateShoppingCartId,
	updateSummary,
	clearShoppingCartState,
	activateShoppingCartLoading,
	desactivateShoppingCartLoading
};
