import { ColorStatusType } from "@/constants/colors.constants";
import { ChipStyle } from "../common.model";

export interface CustomerFinances {
	balance: number;
	invoices: Bill[];
}

export interface Bill {
	id: string;
	billNumber: string;
	period: {
		start: string;
		end?: string;
	};
	dueDate: string;
	totalIncludedAmount: number;
	totalExcludedAmount: number;
	category: string;
	type: BillType;
	financialState?: string;
	state: BillStates;
}

enum BillType {
	REGULAR = "REGULAR",
	CREDIT_NOTE = "CREDIT_NOTE",
	INTERIM = "INTERIM",
	UNKNOWN = "UNKNOWN"
}

enum BillStates {
	PAID = "PAID",
	OVERDUE = "OVERDUE",
	OPEN = "OPEN",
	CANCELLED = "CANCELLED"
}

export const BillStateConfig: Record<BillStates, ChipStyle> = {
	[BillStates.PAID]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.GREEN_LIGHT
	},
	[BillStates.OVERDUE]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.RED_LIGHT
	},
	[BillStates.OPEN]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.ORANGE_LIGHT
	},
	[BillStates.CANCELLED]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.GREY
	}
};
