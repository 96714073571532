import { ProductTransition } from "@/api/models/product-transition/productTransition.model";
import { ProductTransitionProcessService } from "@/api/services/productTransitionProcess.service";

const createProductTransition = ({ commit }: any, payload: any) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.create(payload.productId, payload.transition, payload.shoppingCartId)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getProductTransitionById = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.getById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateProductTransition = ({ commit }: any, payload: any) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.update(payload.id, payload.data)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const sendProductTransition = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.send(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const saveProductTransition = ({ commit }: any, transition: ProductTransition) => {
	commit("setProductTransition", transition);
};

const saveProductTransitionShoppingCartId = ({ commit }: any, shoppingCartId: string) => {
	commit("setProductTransitionShoppingCartId", shoppingCartId);
};

const saveProductTransitionStatus = ({ commit }: any, status: string) => {
	commit("setProductTransitionStatus", status);
};

const getProductTransitionPromotionsAndContracts = (
	{ commit }: any,
	{ productId, shoppingCartId }: { productId: string; shoppingCartId: string }
) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.getTransitionProcessPromotionsAndContracts({ productId, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateTransitionProcessPromotions = ({ commit }: any, payload: { productId: string; shoppingCartId: string; bundles: any[] }) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.putTransitionProcessPromotions(payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateTransitionProcessContracts = ({ commit }: any, payload: { productId: string; shoppingCartId: string; bundles: any[] }) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.putTransitionProcessContracts(payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getProductTransitionConfiguration = ({ commit }: any, { productId, shoppingCartId }: { productId: string; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.getTransitionProcessConfiguration({ productId, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const updateTransitionProcessConfiguration = (
	{ commit }: any,
	payload: { productId: string; shoppingCartId: string; bundles: any[]; parameters: any[] }
) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.putTransitionProcessConfiguration(payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getTransitionProcessSummary = ({ commit }: any, { productId, shoppingCartId }: { productId: string; shoppingCartId: string }) => {
	return new Promise((resolve, reject) => {
		ProductTransitionProcessService.getTransitionProcessSummary({ productId, shoppingCartId })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

export default {
	createProductTransition,
	getProductTransitionById,
	updateProductTransition,
	sendProductTransition,
	saveProductTransition,
	saveProductTransitionShoppingCartId,
	saveProductTransitionStatus,
	getProductTransitionPromotionsAndContracts,
	updateTransitionProcessPromotions,
	updateTransitionProcessContracts,
	getProductTransitionConfiguration,
	updateTransitionProcessConfiguration,
	getTransitionProcessSummary
};
