import { GeneralDialogMode } from "@/api/constants/enums";
interface ComponentsState {
	overlay: {
		active: boolean;
		text: string;
	};
	stepper: {
		step: number;
	};
	generalDialog: {
		opened: boolean;
		i18n: string;
		source: string;
		message: string;
		messages: Array<any>;
		mode: string;
		status: number;
	};
}

const statusComponentInitialState = () => {
	return {
		active: false,
		text: ""
	};
};

const initialState: ComponentsState = {
	overlay: statusComponentInitialState(),
	stepper: {
		step: 1
	},
	generalDialog: {
		opened: false,
		i18n: "",
		source: "",
		message: "",
		messages: [],
		mode: GeneralDialogMode.ERROR,
		status: 0
	}
};

const state: ComponentsState = initialState;
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
	state,
	actions,
	mutations,
	getters
};
