import WorkInProgress from "@/components/WorkInProgress.vue";
import { RouteInfo } from "./types";
import { authGuard, permissionGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewAdministration = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/Administration.vue");
const AdminSectionCache = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/cache/Cache.vue");
const AdminSectionUsers = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/users/Users.vue");
const AdminSectionDependencies = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/dependencies/Dependencies.vue");
const AdminSectionDropdowns = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/dropdowns/Dropdowns.vue");
const AdminSectionChannels = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/channels/Channels.vue");
const AdminSectionBlacklist = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/blacklist/Blacklist.vue");
const AdminSectionBulk = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/bulk/Bulk.vue");
const AdminSectionRetailExclusivityRules = () =>
	import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/retail-exclusivity-rules/RetailExclusivityRules.vue");
const AdminSectionOthers = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/others/Others.vue");
const AdminSectionProductsMandatoryDocuments = () =>
	import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/products-mandatory-docs/ProductsMandatoryDocuments.vue");
const AdminSectionProductsTransition = () =>
	import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/products-transition/ProductsTransition.vue");

const AdminPromotionGroups = () => import(/* webpackChunkName: "chunk-admin" */ "@/views/administration/promotion-groups/PromotionGroups.vue");

export const AdministrationRouteChildren: RouteInfo[] = [
	{
		id: UIPAGE.ADMINISTRATION_CACHE,
		path: "cache",
		component: AdminSectionCache,
		title: "administration.children.cache.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_USERS,
		path: "users",
		component: AdminSectionUsers,
		title: "administration.children.users.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_DEPENDENCIES,
		path: "dependencies",
		component: AdminSectionDependencies,
		title: "administration.children.dependencies.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_DROPDOWNS,
		path: "dropdowns",
		component: AdminSectionDropdowns,
		title: "administration.children.dropdowns.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_CHANNELS,
		path: "channels",
		component: AdminSectionChannels,
		title: "administration.children.channels.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_BLACKLIST,
		path: "blacklist",
		component: AdminSectionBlacklist,
		title: "administration.children.blacklist.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_BULK,
		path: "bulk",
		component: AdminSectionBulk,
		title: "administration.children.bulk.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_PROMOTION_GROUPS,
		path: "promotion-groups",
		component: AdminPromotionGroups,
		title: "administration.children.promotion_groups.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_B2B_SEGMENTS,
		path: "b2b-segments",
		component: WorkInProgress,
		title: "administration.children.b2b_segments.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_RETAIL_EXCLUSIVITY_RULES,
		path: "retail-exclusivity-rules",
		component: AdminSectionRetailExclusivityRules,
		title: "administration.children.retail_exclusivity_rules.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_PRODUCTS_MANDATORY_DOCUMENTS,
		path: "products-mandatory-documents",
		component: AdminSectionProductsMandatoryDocuments,
		title: "administration.children.products_mandatory_documents.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_TRANSITION_CONFIGURATION,
		path: "transition",
		component: AdminSectionProductsTransition,
		title: "administration.children.products_transition.display"
	},
	{
		id: UIPAGE.ADMINISTRATION_OTHERS,
		path: "others",
		component: AdminSectionOthers,
		title: "administration.children.others.display"
	}
];

export const AdministrationRoute: RouteInfo = {
	id: UIPAGE.ADMINISTRATION,
	path: "/administration",
	component: ViewAdministration,
	guards: [authGuard, permissionGuard],
	title: "administration.display",
	icon: "mdi-cogs",
	children: [...AdministrationRouteChildren]
};
