export interface Configuration {
	id?: string;
	bundles: any[]; // TODO
	parameters: Record<string, string>;
}

export enum ConfigurationProductAction {
	ADD = "ADD",
	NO_CHANGE = "NO_CHANGE",
	DELETE = "DELETE",
	CHANGE = "CHANGE"
}
