export const ExtrasMixin = {
	isValidExtra(extra: any) {
		return extra.qty >= extra.min && extra.qty <= extra.max;
	},
	areValidExtras(extras: any[]) {
		for (const bundle of extras) {
			const { required, extras: bundleExtras = [], extraGroups: bundleExtraGroups = [] } = bundle;

			if (required) {
				const validExtras = bundleExtras.every((extra) => this.isValidExtra(extra));
				if (!validExtras) return false;

				const validGroups = bundleExtraGroups.every((extra) => this.isValidExtra(extra));
				if (!validGroups) return false;
			}
		}

		return true;
	},
	isOptionalExtra(extra: any) {
		return extra.min === 0;
	},
	isRequiredExtra(extra: any) {
		return extra.min === 1;
	},
	getRequiredExtrasSetToMin(extras: any[]) {
		return extras.map((extra) => {
			//Required fields set to min
			if (extra.min > 0 && extra.qty < extra.min) {
				extra.qty = extra.min;
			}
			return extra;
		});
	},
	getRequiredExtrasSetToMinOrZero(extras: any[], extraQty: number) {
		return extras.map((extra) => {
			//Required fields set to min
			if (extra.min > 0 && extra.qty < extra.min && extraQty > 0) {
				extra.qty = extra.min;
			} else if (extra.min > 0 && extra.qty >= extra.min && extraQty < 1) {
				//Required fields set to 0
				extra.qty = 0;
			}
			return extra;
		});
	}
};
