import httpClient from "../config/httpClient";

export abstract class NumberPoolService {
	private static readonly NUMBER_POOL_URL = "/sys03/number-pool";
	private static readonly RESERVE_URL = NumberPoolService.NUMBER_POOL_URL.concat("/reserve");
	private static readonly AVAILABLE_URL = NumberPoolService.NUMBER_POOL_URL.concat("/available");

	public static getAvailableNumbers(province: string) {
		const url = `${NumberPoolService.AVAILABLE_URL}/${province}`;
		return httpClient.get(url);
	}

	public static reserveNumber(number: string) {
		const url = `${NumberPoolService.RESERVE_URL}/${number}`;
		return httpClient.put(url);
	}
}
