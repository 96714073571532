import { configurationToConfigurationAPI } from "../adapters/configuration.adapter";
import { contractToContractAPI } from "../adapters/promotions-and-contracts/contracts.adapter";
import { bundlesToExtendedBundles } from "../adapters/promotions-and-contracts/extendedBundle.adapter";
import { promotionsToPromotionsAPI } from "../adapters/promotions-and-contracts/promotions.adapter";
import httpClient from "../config/httpClient";
import { CustomerType } from "../models/customer";

const v1Url = `${process.env.VUE_APP_API_URL}/v1/api`;
export abstract class ShoppingCartService {
	private static readonly BASE_URL = "/sys03/";
	private static readonly SHOPPING_CART_URL = ShoppingCartService.BASE_URL.concat("shopping-cart");
	private static readonly AVAILABLE_PRODUCTS_URL = "available-products";
	private static readonly DEFAULT_SUMMARY_URL = "available-product";
	private static readonly SUMMARY_URL = "summary";
	private static readonly EXTRAS_URL = "extras";
	private static readonly CONFIGURATION_URL = "configuration";
	private static readonly PROMOTIONS_URL = "promotions";
	private static readonly CONTRACTS_URL = "contracts";
	private static readonly PROMOTIONS_AND_CONTRACTS_URL = "promotions-and-contracts";

	public static create(apartmentId: string, customerType: CustomerType, products?: any) {
		return httpClient.post(this.SHOPPING_CART_URL, {
			apartmentId,
			customerType,
			products
		});
	}

	public static getAvailableProducts(apartmentId: string, customerType: CustomerType) {
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${ShoppingCartService.AVAILABLE_PRODUCTS_URL}`;
		return httpClient.post(url, {
			apartmentId,
			customerType
		});
	}

	/**
	 * Gets the default shopping cart summary according to a product
	 * @param shoppingCartId
	 * @param productName
	 */
	public static getDefaultSummary(shoppingCartId: string, productName: string) {
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${shoppingCartId}/${ShoppingCartService.DEFAULT_SUMMARY_URL}/${productName}`;
		return httpClient.get(url);
	}

	/**
	 * Gets the shopping cart summary
	 * @param shoppingCartId
	 */
	public static getSummary(shoppingCartId: string) {
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${shoppingCartId}/${ShoppingCartService.SUMMARY_URL}`;
		return httpClient.get(url);
	}

	public static getExtras(shoppingCartId: string) {
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${shoppingCartId}/${ShoppingCartService.EXTRAS_URL}`;
		return httpClient.get(url);
	}

	public static putExtras(shoppingCartId: string, extraBundles: any) {
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${shoppingCartId}/${ShoppingCartService.EXTRAS_URL}`;

		const formattedExtras = extraBundles.map((bundle) => {
			return {
				name: bundle.name,
				extras: bundle.extras,
				extraGroups: bundle.extraGroups
			};
		});

		return httpClient.put(url, formattedExtras);
	}

	public static getConfiguration(shoppingCartId: string) {
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${shoppingCartId}/${ShoppingCartService.CONFIGURATION_URL}`;
		return httpClient.get(url);
	}

	public static putConfiguration(shoppingCartId: string, { bundles, parameters }: any) {
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${shoppingCartId}/${ShoppingCartService.CONFIGURATION_URL}`;
		return httpClient.put(url, configurationToConfigurationAPI({ bundles, parameters }));
	}

	public static putPromotions({ shoppingCartId, bundles = [] }: { shoppingCartId: string; bundles: any[] }) {
		const formattedBundles = bundles.map((bundle) => ({ ...bundle, promotions: promotionsToPromotionsAPI(bundle.promotions) }));
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${shoppingCartId}/${ShoppingCartService.PROMOTIONS_URL}`;
		return httpClient
			.put(url, { bundles: formattedBundles })
			.then((response) => ({ ...response.data, bundles: bundlesToExtendedBundles(response.data.bundles) }));
	}

	public static putContracts({ shoppingCartId, bundles = [] }: { shoppingCartId: string; bundles: any[] }) {
		const formattedBundles = bundles.map((bundle) => ({ ...bundle, contract: contractToContractAPI(bundle.contract) }));
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${shoppingCartId}/${ShoppingCartService.CONTRACTS_URL}`;
		return httpClient
			.put(url, { bundles: formattedBundles })
			.then((response) => ({ ...response.data, bundles: bundlesToExtendedBundles(response.data.bundles) }));
	}

	public static getPromotionsAndContracts(shoppingCartId: string) {
		const url = `${v1Url}${ShoppingCartService.SHOPPING_CART_URL}/${shoppingCartId}/${ShoppingCartService.PROMOTIONS_AND_CONTRACTS_URL}`;
		return httpClient.get(url).then((response) => ({ ...response.data, bundles: bundlesToExtendedBundles(response.data.bundles) }));
	}
}
