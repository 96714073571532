export interface Identification {
	type: IdentificationType;
	number: string;
	country: string;
}

export enum IdentificationType {
	DNI = "dni",
	NIE = "nie",
	CIF = "cif",
	CIF_EXTRANJERO = "cifExtranjero",
	PASSPORT = "passport"
}
