import { Identification, IdentificationType } from "@/api/models/sale/identification.model";
import { E_FOREIGN_CIF_COUNTRY_NOT_ALLOWED, E_PASSPORT_COUNTRY_NOT_ALLOWED } from "@/constants/error.constants";

export const CustomerIdentificationMixin = {
	getIdentificationTypeError({ identification }: { identification?: Partial<Identification> }): undefined | string {
		if (!identification) return;

		const { type, country } = identification;
		if (country !== "ES") return;

		if (type === IdentificationType.PASSPORT) {
			return E_PASSPORT_COUNTRY_NOT_ALLOWED;
		}

		if (type === IdentificationType.CIF_EXTRANJERO) {
			return E_FOREIGN_CIF_COUNTRY_NOT_ALLOWED;
		}
	}
};
