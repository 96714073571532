import { Route } from "vue-router";
import store from "@/store/index";

const permissionCanActivate = ({ routeId }: { routeId: string }) => {
	const permissions = ((store["state"] as any).user?.permissions as string[]) ?? [];

	const canActivate = permissions.some((permission) => {
		const permissionRegexp = new RegExp(`^${permission}$`);

		return permissionRegexp.test(routeId);
	});
	return canActivate;
};

const permissionGuard = (to: Route, from: Route, next: (redirect?: string) => void) => {
	const routeId = to.meta?.id;
	if (permissionCanActivate({ routeId })) {
		next();
	} else {
		next("/");
	}
};

export { permissionGuard, permissionCanActivate };
