import axios from "axios";
import { authRequestInterceptorDevelopment, authErrorResponseInterceptor } from "../interceptors/authInterceptor";

const url = `${process.env.VUE_APP_API_URL}/v1/api`;

const httpClient = axios.create({
	baseURL: url,
	withCredentials: true,
	timeout: 300000,
	headers: {
		"Content-Type": "application/json"
	},
	timeoutErrorMessage: "AXIOS_TIMEOUT"
});

httpClient.interceptors.request.use(authRequestInterceptorDevelopment);
httpClient.interceptors.response.use(undefined, authErrorResponseInterceptor);

export default httpClient;
