import { RouteInfo } from "./types";
import { authLoginGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewLogin = () => import(/* webpackChunkName: "chunk-login" */ "@/views/login/Login.vue");

export const LoginRoute: RouteInfo = {
	id: UIPAGE.LOGIN,
	path: "/login",
	component: ViewLogin,
	guards: [authLoginGuard],
	title: "login.login"
};
