const getProductMultipleTransition = (state) => {
	return state.productTransition;
};

const getProductMultipleTransitionShoppingCartId = (state) => {
	return state.shoppingCartId;
};

const getProductMultipleTransitionStatus = (state) => {
	return state.status;
};

export default {
	getProductMultipleTransition,
	getProductMultipleTransitionShoppingCartId,
	getProductMultipleTransitionStatus
};
