import { RouteInfo } from "./types";
import { authGuard, permissionGuard, saleStatusGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewSale = () => import(/* webpackChunkName: "chunk-sale" */ "@/views/sale/Sale.vue");
const SaleStepIdentificationStep = () => import(/* webpackChunkName: "chunk-sale" */ "@/views/sale/identification-step/IdentificationStep.vue");
const SaleStepSignalStep = () => import(/* webpackChunkName: "chunk-sale" */ "@/views/sale/signal-step/SignalStep.vue");
const SaleStepCustomerTypeStep = () => import(/* webpackChunkName: "chunk-sale" */ "@/views/sale/customer-type-step/CustomerTypeStep.vue");
const SaleStepBundleStep = () => import(/* webpackChunkName: "chunk-sale" */ "@/views/sale/bundle-step/BundleStep.vue");
const SaleStepExtrasStep = () => import(/* webpackChunkName: "chunk-sale" */ "@/views/sale/extras-step/ExtrasStep.vue");
const SaleStepPromotionsStep = () => import(/* webpackChunkName: "chunk-sale" */ "@/views/sale/promotions-step/PromotionsStep.vue");
const SaleStepConfigurationStep = () => import(/* webpackChunkName: "chunk-sale" */ "@/views/sale/configuration-step/ConfigurationStep.vue");
const SaleStepContractStep = () => import(/* webpackChunkName: "chunk-sale" */ "@/views/sale/contract-step/ContractStep.vue");

export const SaleRoute: RouteInfo = {
	id: UIPAGE.SALE,
	path: "/sale",
	component: ViewSale,
	guards: [authGuard, permissionGuard, saleStatusGuard],
	title: "sale.display",
	icon: "mdi-shopping-outline",
	children: [
		{
			id: UIPAGE.SALE_IDENTIFICATION,
			path: "identification",
			component: SaleStepIdentificationStep,
			props: (route) => ({
				name: route.query.name,
				phone: route.query.phone,
				campaign: route.query.campaign,
				callType: route.query.callType
			}),
			title: "sale.display"
		},
		{
			id: UIPAGE.SALE_COVERAGE,
			path: "coverage",
			component: SaleStepSignalStep,
			title: "sale.display"
		},
		{
			id: UIPAGE.SALE_CUSTOMER,
			path: "customer",
			component: SaleStepCustomerTypeStep,
			title: "sale.display"
		},
		{
			id: UIPAGE.SALE_BUNDLE,
			path: "bundle",
			component: SaleStepBundleStep,
			title: "sale.display"
		},
		{
			id: UIPAGE.SALE_EXTRAS,
			path: "extras",
			component: SaleStepExtrasStep,
			title: "sale.display"
		},
		{
			id: UIPAGE.SALE_PROMOTIONS,
			path: "promotions",
			component: SaleStepPromotionsStep,
			title: "sale.display"
		},
		{
			id: UIPAGE.SALE_CONFIGURATION,
			path: "configuration",
			component: SaleStepConfigurationStep,
			title: "sale.display"
		},
		{
			id: UIPAGE.SALE_CONTRACT,
			path: "contract",
			component: SaleStepContractStep,
			title: "sale.display"
		},
		{
			id: "sale-empty",
			path: "",
			redirect: "identification"
		}
	]
};
