import { GeneralDialogMode } from "@/api/constants/enums";

const parseError = ({ error }: { error: any }) => {
	if (error) {
		const { response = {}, code, message } = error;
		const { data, status, statusText } = response;

		if (data && typeof data === "object") {
			return {
				mode: GeneralDialogMode.ERROR,
				...data
			};
		} else if (code) {
			return {
				mode: GeneralDialogMode.ERROR,
				i18n: code,
				message: `${code} ${message ? `- ${message}` : ""}`
			};
		} else if (message === "Network Error") {
			return {
				mode: GeneralDialogMode.ERROR,
				i18n: "E_CORS",
				message: `E_CORS ${message ? `- ${message}` : ""}`
			};
		}

		return {
			mode: GeneralDialogMode.ERROR,
			i18n: "E_UNHANDLED",
			message: `error unhandled, status: ${status}, statusText: ${statusText}, message: ${message}`
		};
	}

	return {
		mode: GeneralDialogMode.ERROR,
		i18n: "E_UNDEFINED",
		message: "'error' is undefined"
	};
};

const handleError = ({ commit }: any, { error }: { error: any }) => {
	commit("desactivateDialog");
	commit("desactivateOverlay");

	const errorParsed = parseError({ error });
	const { message } = errorParsed;
	if (message !== "ECONNABORTED - Request aborted") {
		commit("activateDialog", errorParsed);
	}
};

const handleBulkError = ({ dispatch }: any, { error }: { error: any }) => {
	const { response: { status = undefined } = {}, message } = error;

	// API GW: Gateway Time-out or CORS error
	if (status !== 504 && !["Network Error", "AXIOS_TIMEOUT"].includes(message)) {
		dispatch("handleError", { error });
	}
};

export default {
	actions: {
		handleError,
		handleBulkError
	}
};
