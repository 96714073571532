export default {
	mounted() {
		const tableContainers = document.querySelectorAll(".v-data-table__wrapper");

		tableContainers.forEach((tableContainer) => {
			const startColumnFixed = tableContainer.querySelectorAll(".fixed.start-column");
			const endColumnFixed = tableContainer.querySelectorAll(".fixed.end-column");

			tableContainer.addEventListener("scroll", function () {
				if (tableContainer.scrollLeft > 0) {
					startColumnFixed.forEach((element) => element.classList.add("shadow-left"));
				} else {
					startColumnFixed.forEach((element) => element.classList.remove("shadow-left"));
				}

				const maxScrollLeft = tableContainer.scrollWidth - tableContainer.clientWidth;
				if (tableContainer.scrollLeft < maxScrollLeft) {
					endColumnFixed.forEach((element) => element.classList.add("shadow-right"));
				} else {
					endColumnFixed.forEach((element) => element.classList.remove("shadow-right"));
				}
			});
		});
	}
};
