import { ColorStatusType } from "@/constants/colors.constants";
import { ChipStyle } from "../common.model";

export interface CustomerTask {
	id: string;
	state: string;
	category: string;
	type: string;
	subType: string;
	createdBy: string;
	createdAt: string;
	isExpanded?: boolean;
}

export interface CustomerTaskDetail extends CustomerTask {
	assignedTo: string;
	description: string;
	contact: string;
	otrsQueueName: string;
	closedBy?: string;
	closedAt?: string;
	closedDescription?: string;
}

enum TaskStates {
	ACKNOWLEDGED = "Acknowledged",
	IN_PROGRESS = "InProgress",
	NEW = "New",
	RESOLVED = "Resolved"
}

export const TaskStateConfig: Record<TaskStates, ChipStyle> = {
	[TaskStates.ACKNOWLEDGED]: {
		color: ColorStatusType.GREY,
		background: ColorStatusType.GREY_LIGHT
	},
	[TaskStates.NEW]: {
		color: ColorStatusType.GREY,
		background: ColorStatusType.GREY_LIGHT
	},
	[TaskStates.IN_PROGRESS]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.GREY
	},
	[TaskStates.RESOLVED]: {
		color: ColorStatusType.WHITE,
		background: ColorStatusType.GREEN_LIGHT
	}
};
